import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import initCustomLogger from './logger';

initCustomLogger();
const lifecycles = singleSpaReact({
  React,
  ReactDOM: ReactDOMClient,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  renderType: "createRoot"
});

export const { bootstrap, mount, unmount } = lifecycles;
