import React, { useState } from "react";
import playIcon from "../../../assets/img/playIcon.svg";
import pauseIcon from "../../../assets/img/pauseIcon.svg";
import stopIcon from "../../../assets/img/stopIcon.svg";
import { navigateToUrl } from "single-spa";
import PropTypes from "prop-types";

import { DDS_DEMO_STATES, DDS_DEMO_CONTROLS } from "../../../constants";

import { StyledImg, CardContent, IconButtonStyle, StyledDiv } from "./style";

import { CONFIRMER_MESSAGES } from "../../../constants/en-us";

import { Backdrop, Box } from "@mui/material";
import Confirmer from "../../Confirmer";
import { formatTime } from "../../../utils/helper";
import useTimer from "../../../utils/hooks/useTimer";
import { useEffect } from "react";

const DemoControlModule = ({
  currentDemoDetails,
  setCurrentDemoDetails,
  timeFromTimerInSeconds,
  configureDemoStateCall,
}) => {
  const [openDemoCloseConfirmer, setOpenDemoCloseConfirmer] = useState(false);
  const { timer, handleStart, handlePause, handleResume, handleStop } =
    useTimer(timeFromTimerInSeconds);

  useEffect(() => {
    if (currentDemoDetails.demoState === DDS_DEMO_STATES.IN_PROGRESS) {
      pauseButtonClicked();
    }
  }, []);

  useEffect(() => {
    /* this will stop the demo once demo time is over */
    if (currentDemoDetails.duration * 60 <= timer) {
      updateDemoStateAndRedirectOnDemoClosure();
    }
  }, [timer]);

  /**
   * Function which gets triggered when play button is clicked
   */
  const playButtonClicked = () => {
    if (currentDemoDetails.demoState === DDS_DEMO_STATES.PAUSED) {
      handleResume();
    } else {
      handleStart();
    }
    configureDemoStateCall({
      demoName: currentDemoDetails.demoName,
      state: DDS_DEMO_CONTROLS.PLAY,
      timeElapsedInSeconds: timer,
    });
    setCurrentDemoDetails((prevDemoState) => {
      return { ...prevDemoState, demoState: DDS_DEMO_STATES.IN_PROGRESS };
    });
  };

  /**
   * Function which gets triggered when pause button is clicked
   */
  const pauseButtonClicked = () => {
    handlePause();
    configureDemoStateCall({
      demoName: currentDemoDetails.demoName,
      state: DDS_DEMO_CONTROLS.PAUSE,
      timeElapsedInSeconds: timer,
    });

    setCurrentDemoDetails((prevDemoState) => {
      return { ...prevDemoState, demoState: DDS_DEMO_STATES.PAUSED };
    });
  };

  /**
   * Function which gets triggered when stop button is clicked
   */
  const stopButtonClicked = () => {
    pauseButtonClicked();
    setOpenDemoCloseConfirmer(true);
  };

  /**
   * Logic to disable play button
   */
  const disablePlayButtion = () => {
    const demoState = currentDemoDetails.demoState;
    return DDS_DEMO_STATES.IN_PROGRESS === demoState;
  };

  /**
   * Logic to disable pause button
   */
  const disablePauseButton = () => {
    return (
      DDS_DEMO_STATES.PAUSED === currentDemoDetails.demoState ||
      DDS_DEMO_STATES.READY === currentDemoDetails.demoState
    );
  };

  /**
   * Logic to disable stop button
   */
  const disableStopButton = () => {
    return DDS_DEMO_STATES.CREATED === currentDemoDetails.demoState;
  };

  /**
   * Actions to be taken when demo stop is confirmed
   */
  const confirmYesButtonActionForStopDemo = () => {
    configureDemoStateCall({
      demoName: currentDemoDetails.demoName,
      state: DDS_DEMO_CONTROLS.STOP,
      timeElapsedInSeconds: timer,
    });
    handleStop();
    // Close the modal
    setOpenDemoCloseConfirmer(false);

    updateDemoStateAndRedirectOnDemoClosure();
  };

  /**
   * Actions to be taken when demo stop is declined
   */
  const confirmNoButtonActionForStopDemo = () => {
    // Resume the demo
    playButtonClicked();

    // Close the modal
    setOpenDemoCloseConfirmer(false);
  };

  /**
   * Update demo state to CREATED and redirect to main menu screen on demo completion
   * @param {*} setCurrentDemoDetails
   */
  const updateDemoStateAndRedirectOnDemoClosure = () => {
    setCurrentDemoDetails((prevDemoState) => {
      return { ...prevDemoState, demoState: DDS_DEMO_STATES.CREATED };
    });

    // URL path of Demo Data Simulator
    const ddsUrl = "/demo_data_simulator";

    // Navigate to DDS page
    navigateToUrl(ddsUrl);
  };

  return (
    <CardContent>
      <IconButtonStyle
        size="small"
        disabled={disablePlayButtion()}
        onClick={playButtonClicked}
      >
        <StyledImg src={playIcon} alt="" /> :
      </IconButtonStyle>
      <IconButtonStyle
        size="small"
        disabled={disablePauseButton()}
        onClick={pauseButtonClicked}
      >
        <StyledImg src={pauseIcon} alt="" />
      </IconButtonStyle>
      <IconButtonStyle
        size="small"
        disabled={disableStopButton()}
        onClick={stopButtonClicked}
      >
        <StyledImg src={stopIcon} alt="" />
      </IconButtonStyle>
      <StyledDiv>{formatTime(timer)}</StyledDiv>
      <Box>
        {openDemoCloseConfirmer && (
          <Backdrop open sx={{ zIndex: 100 }}>
            <Confirmer
              message={CONFIRMER_MESSAGES.DEMO_CLOSE}
              YesHandler={confirmYesButtonActionForStopDemo}
              NoHandler={confirmNoButtonActionForStopDemo}
            />
          </Backdrop>
        )}
      </Box>
      {/** Stop Button Modal Code ends here */}
    </CardContent>
  );
};

DemoControlModule.propTypes = {
  currentDemoDetails: PropTypes.shape({
    demoState: PropTypes.string,
    duration: PropTypes.number,
    timeElapsed: PropTypes.number,
    demoName: PropTypes.string,
  }),
  setCurrentDemoDetails: PropTypes.func.isRequired,
  configureDemoStateCall: PropTypes.func,
  timeFromTimerInSeconds: PropTypes.number,
};

export default DemoControlModule;
