import React from "react";
import { Popper, Box, Typography, styled } from "@mui/material";
import PropTypes from "prop-types";
import { useSpring, animated } from "@react-spring/web";
import { theme as muiTheme } from "@smf/ui-util-app";
import TextField from "../TextField";
import Button from "../Button";
import InlineErrorMessages from "../InlineErrorMessage";
import { BUTTON_TYPE } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";

const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "700",
  color: theme.customColors.white,
}));

const TypographyUpdatingTitle = styled(Typography)(({ theme }) => ({
  color: theme.customColors.white,
}));

const TypographyEditCompanyLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "400",
  color: theme.customColors.white,
}));

const TypographyErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.errorColor,
}));

const Fade = React.forwardRef((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 5 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const CompanyNameDialog = (props) => {
  const {
    id,
    anchorEl = null,
    open = false,
    companyName = null,
    handleCompanyNameChange,
    handleCancel,
    handleSave,
    isSaveDisabled,
    loading = false,
    error = null,
    placement = "bottom-start",
  } = props;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      placement={placement}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box
            bgcolor={muiTheme.customColors.eerieBlack}
            paddingTop={4}
            paddingBottom={2}
            paddingLeft={2}
            paddingRight={2}
            borderRadius={1.25}
            width={500}
          >
            <Box marginBottom={5}>
              <TypographyTitle variant="h5">
                {MESSAGE_STRINGS["WRAPPER.header.editCompany.title"]}
              </TypographyTitle>
            </Box>
            <Box marginBottom={2}>
              <TypographyEditCompanyLabel variant="h6">
                {MESSAGE_STRINGS["WRAPPER.header.editCompany.companyNameTitle"]}
              </TypographyEditCompanyLabel>
            </Box>
            <Box marginBottom={5}>
              <TextField
                value={companyName.value}
                onChange={handleCompanyNameChange}
                placeholder={
                  MESSAGE_STRINGS["WRAPPER.header.editCompany.placeholder"]
                }
                maxLen={255}
                fullWidth={false}
                helperText={
                  companyName.error ? (
                    <InlineErrorMessages message={companyName.error} />
                  ) : (
                    ""
                  )
                }
                error={Boolean(companyName.error.length)}
              />
            </Box>
            {loading && (
              <Box marginBottom={2}>
                <TypographyUpdatingTitle>
                  {MESSAGE_STRINGS["WRAPPER.header.editCompany.updating"]}
                </TypographyUpdatingTitle>
              </Box>
            )}
            {error && error.isError && (
              <Box marginBottom={2}>
                <TypographyErrorMessage variant="h6">
                  {error.message}
                </TypographyErrorMessage>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button
                type={BUTTON_TYPE.SECONDARY}
                text={MESSAGE_STRINGS["WRAPPER.header.editCompany.cancel"]}
                onClick={handleCancel}
              />
              <Button
                type={BUTTON_TYPE.PRIMARY}
                text={MESSAGE_STRINGS["WRAPPER.header.editCompany.save"]}
                onClick={handleSave}
                disable={isSaveDisabled}
              />
            </Box>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

CompanyNameDialog.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  companyName: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  handleCompanyNameChange: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
    isError: PropTypes.bool,
  }),
  placement: PropTypes.string,
};

export default CompanyNameDialog;
