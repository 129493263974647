import { company, notification, dds, logApi, instance } from '@smf/ui-util-app';

const NOTIFICATION_UPSERT_ACTION = {
  SNOOZE: 'snooze',
  MARK_AS_READ: 'isRead',
};

export async function getPresignedUrl(url,params) {
  const result = await instance.get(url, { params });
  return result.data;
}

export async function getCompanyDetails(params) {
  return company.getCompanyDetails(params);
}

export async function putCompanyName(params) {
  return company.putCompanyName(params);
}

export async function getAllNotificationsForPlant(plantId, email) {
  return notification.getAllNotificationsForPlant({ plantId, email });
}

export async function dismissNotificationForPlant(
  plantId,
  uuid,
  notificationType
) {
  return notification.dismissNotification({ plantId, uuid, notificationType });
}

// API for snoozing and unsnoozing an alert
export async function snoozeNotificationForUser(uuid, userId, snoozeStartTime, isSnoozed) {
  return notification.upsertUserAction({
    uuid,
    userId,
    snoozeStartTime: snoozeStartTime.toString(),
    isSnoozed,
    type: NOTIFICATION_UPSERT_ACTION.SNOOZE,
  });
}

export async function markNotificationAsReadForUser(plantId, userId) {
  return notification.upsertUserAction({
    plantId,
    userId,
    type: NOTIFICATION_UPSERT_ACTION.MARK_AS_READ,
  });
}

/**
 * Fetch list of all running demos for Demo Data Simulator
 * @returns
 */
export async function getRunningDemoDetails() {
  const res = await dds.getRunningDemoDetails();
  return res;
}

/**
 * API call to configure demo state ie play, pause or stop
 * @param {*} data
 * @returns
 */
export async function configureDemoState(data) {
  return dds.configureDemoState(data);
}

/**
 * Save log APi
 * @param {*} data
 * @returns
 */
export async function saveLogs(data) {
  return logApi.saveLogs(data);
}