import { useEffect } from "react";
import { useRxjsState } from "./useRxjsState";
import { useToastContext } from "../../context/toastContext";
import { TOAST_REDUCER_CONSTANTS } from "../../constants";
import { updateRxjsState } from "../rxjsStateNext";

/**
 * Global websocket connection Listner
 *
 * @param channel  channel/WSS-URL identifier
 * @param onSuccess  callback Function for success
 * @param onError  callback Function for error
 *
 * @author Aniket Soni
 */
const useWssListener = (channel, onSuccess, onError, onClose = () => {}) => {
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  useEffect(
    () => {
      let websocket = rxjsState?.wss?.[channel]?.connection;

      if (websocket) {
        websocket.onmessage = (e) => {
          const data = JSON.parse(e.data);
          onSuccess(data);
        };

        websocket.onerror = (e) => {
          onError(e);
        };

        websocket.onclose = (e) => {
          onClose(e);
        };
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [rxjsState?.wss?.[channel]?.connection],
    channel
  );

  useEffect(() => {
    if (rxjsState?.wss?.[channel]?.errorStatus) {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message:
            rxjsState?.wss?.[channel]?.errorMessage ||
            "Error Connecting!, Please reload the app.",
        },
      });
      updateRxjsState({
        wss: {
          ...rxjsState.wss,
          [channel]: {
            ...rxjsState.wss?.[channel],
            errorStatus: false,
            errorMessage: null,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rxjsState?.wss?.[channel]?.errorStatus]);
};

export default useWssListener;
