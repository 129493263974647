import { useEffect, useState, useRef, useReducer } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getAllNotificationsForPlant,
  dismissNotificationForPlant,
  snoozeNotificationForUser,
  markNotificationAsReadForUser
} from '../apiHelper';
import {
  initialNotificationsState,
  notificationsReducer,
} from '../notificationsReducer';
import { useRxjsState } from './useRxjsState';
import { config } from '@smf/ui-util-app';
import {
  NOTIFICATION_REDUCER_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { useToastContext } from '../../context/toastContext';
import MESSAGE_STRINGS from '../../constants/en-us';

const WSS_ENDPOINT_NOTIFICATION = `wss://${config.BASE_WS_API_URL}/notificationws`;
const CLOSE_EVENT_REASON = 'deliberate disconnect';
const useNotificationsListener = () => {
  const {
    rxjsState: { plantId, sessionToken, userData },
  } = useRxjsState();
  const [notificationsState, notificationsDispatch] = useReducer(
    notificationsReducer,
    initialNotificationsState
  );
  const [notificationsSocketUrl, setNotificationsSocketUrl] = useState(null);
  const ws = useRef(null);
  const { toastDispatch } = useToastContext();

  useEffect(() => {
    const url =
      plantId && sessionToken && userData?.email
        ? `${WSS_ENDPOINT_NOTIFICATION}?plantId=${plantId}&emailId=${userData.email}&token=${sessionToken}`
        : null;
    setNotificationsSocketUrl(url);
  }, [plantId, sessionToken, userData?.email]);

  const { isFetching: isLoadingInitialData, refetch: getAllNotificationsRefetch } = useQuery(
    ['get-all-notifications', plantId],
    () => getAllNotificationsForPlant(plantId, userData?.email),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!plantId,
      onSuccess: (response = []) => {
        notificationsDispatch({
          type: NOTIFICATION_REDUCER_CONSTANTS.INITIALIZE,
          payload: { notifications: response },
        });
      },
      onError: (error) => {
        notificationsDispatch({ type: NOTIFICATION_REDUCER_CONSTANTS.RESET });
        console.error(error);
      },
    }
  );

  const connectWebSocket = (notificationsSocketUrl) => {
    if (!notificationsSocketUrl) return;
    ws.current = new WebSocket(notificationsSocketUrl);
    ws.current.onopen = (event) => {
      console.info('notification ws connected at ', event.timeStamp);
    };
    ws.current.onclose = (event) => {
      console.info('notification ws disconnected due to ', event.reason);
      if (event.code === 4001) {
        notificationsDispatch({ type: NOTIFICATION_REDUCER_CONSTANTS.RESET });
      } else {
        console.info('attempt to re-establish notification ws');
        connectWebSocket(notificationsSocketUrl);
      }
    };
    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if ('uuid' in message) {
        notificationsDispatch({
          type: NOTIFICATION_REDUCER_CONSTANTS.APPEND,
          payload: { notification: message },
        });
      }
    };
    ws.current.onError = (event) => {
      console.error('notification ws error due to ', event.reason);
    };
  };

  useEffect(() => {
    if (ws.current) ws.current.close(4001, CLOSE_EVENT_REASON);
    if (notificationsSocketUrl && !isLoadingInitialData) {
      connectWebSocket(notificationsSocketUrl);
    }

    return () => {
      if (!ws.current) return;
      ws.current.close(4001, CLOSE_EVENT_REASON);
    };
  }, [notificationsSocketUrl, isLoadingInitialData]);

  const dismissNotification = async (uuid, notificationType) => {
    const result = await dismissNotificationForPlant(
      plantId,
      uuid,
      notificationType
    );
    if (result?.message === 'success') {
      if (notificationType) {
        notificationsDispatch({
          type: NOTIFICATION_REDUCER_CONSTANTS.DISMISS_PUSH_NOTIFICATION,
          payload: { uuid, notificationType },
        });
      } else {
        notificationsDispatch({
          type: NOTIFICATION_REDUCER_CONSTANTS.DISMISS,
          payload: { uuid },
        });
      }
    } else {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: 'Error occured while dismissing notification', // TODO - Move to en-us constants file after cleaning it up
        },
      });
    }
  };

  // API call for snoozing and unsnoozing an alert
  const toggleSnooze = async (uuid, snoozeStartTime, isSnoozed, updatedAlert) => {
    const result = await snoozeNotificationForUser(
      uuid,
      userData?.email,
      snoozeStartTime, 
      isSnoozed
    );
    if (result?.status === 200) {
      isSnoozed  
        ? notificationsDispatch({
          type: NOTIFICATION_REDUCER_CONSTANTS.MARK_AS_SNOOZED,
          payload: { uuid, snoozeStartTime },
        })
        : appendExistingAlert(updatedAlert, true);
    } else {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    }
    return result;
  };


  const updateRecurrenceInterval = (uuid, intervalId) => {
    // add recurrence interval if it does not exist
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.UPDATE_RECURRENCE_INTERVAL,
      payload: { uuid, intervalId },
    });
  };

  const updateSnoozeTimer = (uuid, timeoutId) => {
    // add snooze timer if it does not exist
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.UPDATE_SNOOZE_TIMER,
      payload: { uuid, timeoutId },
    });
  };

  const appendExistingAlert = (notification, isSnoozeAction) => {
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.APPEND_EXISTING_ALERT,
      payload: { notification, isSnoozeAction },
    });
  };

  const {
    mutate: markNotificationAsRead,
  } = useMutation(
    ['mark-as-read'],
    async () => {
      const result = await markNotificationAsReadForUser(plantId, userData?.email);
      return result;
    },
    {
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
        getAllNotificationsRefetch();
      },
    }
  );

  const showNotificationCenter = (isVisible) => {
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.TOGGLE_NOTIFICATION_CENTER,
      payload: { isVisible },
    });
  };

  const showPushNotifications = (isVisible) => {
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.TOGGLE_PUSH_NOTIFICATIONS,
      payload: { isVisible },
    });
  };

  return {
    isLoadingInitialData,
    markNotificationAsRead,
    notificationsState,
    dismissNotification,
    toggleSnooze,
    updateRecurrenceInterval,
    updateSnoozeTimer,
    appendExistingAlert,
    showNotificationCenter,
    showPushNotifications,
    notificationsDispatch,
  };
};

export default useNotificationsListener;
