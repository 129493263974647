import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Accordion,
} from '@mui/material';
import { styled } from '@mui/system';

const NotificationCenter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '28.125rem',
  height: '4.875rem',
  right: '1.375rem',
  top: '4.45rem',
  filter: 'drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25))',
  borderRadius: '0.25rem',
  background: theme.customColors.black,
}));
const NotificationCenterHeader = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',

  width: '28.125rem',
  height: '11.125rem',

  boxShadow: '0.25rem 0.25rem 0.25rem rgb(0 0 0 / 25%)',
  borderWidth: '0.0625rem 0.0625rem 0 0.0625rem',
  borderStyle: 'solid',
  borderColor: theme.customColors.wrapperGrey,
  filter: 'drop-shadow(0 0.25rem 0.5rem rgba(0, 0, 0, 0.25))',
  borderRadius: '0.25rem 0.25rem 0 0',
  backgroundColor: theme.customColors.black,
}));
const TitleFrame = styled(Box)(() => ({
  width: '28.125rem',
  height: '4.1875rem',

  borderRadius: '0.25rem',
}));
const NotificationCenterTitle = styled(Typography)(({ theme }) => ({
  width: '21.375rem',
  height: '1.6875rem',
  position: 'absolute',
  top: '1.25rem',
  left: '1.25rem',

  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '1.375rem',

  color: theme.palette.common.white,
}));
const CategoryToggle = styled(Box)(() => ({
  display: 'flex',
  width: '17.75rem',
  height: '4.125rem',
  marginLeft: '10.375rem',
}));
const TimeButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  width: '7.875rem',
  height: '1.875rem',

  borderRadius: '0.25rem 0 0 0.25rem',
  margin: 'auto 0 auto auto;',
  background: isSelected ? theme.customColors.black : theme.customColors.white,
  color: isSelected ? theme.customColors.white : theme.customColors.black,

  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  padding: '0',
  textTransform: 'capitalize',
  border: `0.0625rem solid ${theme.customColors.white}`,
  '&:hover': {
    background: isSelected ? theme.customColors.white : theme.customColors.black,
    color: isSelected ? theme.customColors.black : theme.customColors.white,
  },
}));
const FunctionalButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  width: '8.125rem',
  height: '1.875rem',
  margin: 'auto',

  background: isSelected ? theme.customColors.black : theme.customColors.white,
  borderRadius: '0 0.25rem 0.25rem 0',
  marginLeft: '0',

  color: isSelected ? theme.customColors.white : theme.customColors.black,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  padding: '0',
  textTransform: 'capitalize',
  border: `0.0625rem solid ${theme.customColors.white}`,

  '&:hover': {
    background: isSelected ? theme.customColors.white : theme.customColors.black,
    color: isSelected ? theme.customColors.black : theme.customColors.white,
  },
}));
const ExpandAndCollapseToggle = styled(Box)(() => ({
  width: '12.3125rem',
  height: '2.4375rem',
  position: 'absolute',
  top: '8.625rem',
  left: '15.6875rem',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0rem',

  '& hr': {
    height: '1.25rem',
    width: '0.0625rem',
  },
}));
const ExpandAndCollapsButton = styled(Button)(({ theme }) => ({
  width: '16.3125rem',
  height: '2.4375rem',
  textTransform: 'capitalize',
  fontSize: '0.875rem',
  color: theme.palette.common.white,

  '&.Mui-disabled': {
    color: theme.palette.border.darkGrey,
  },
}));
const NotificationCenterList = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '28.125rem',
  height: 'calc(100vh - 16rem - 1rem)',
  overflow: 'hidden',
  overflowY: 'scroll',
  borderWidth: '0 0.0625rem 0.0625rem 0.0625rem',
  borderStyle: 'solid',
  borderColor: theme.customColors.wrapperGrey,
  filter: 'drop-shadow(0 0.25rem 0.5rem rgba(0, 0, 0, 0.25))',
  borderRadius: '0 0 0.25rem 0.25rem',
  flex: 'none',
  order: '1',
  flexGrow: '0',
  backgroundColor: theme.customColors.black,
}));
const NotificationCenterEmptyList = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '28.125rem',
  height: '4.875rem',

  background: theme.customColors.black,

  borderWidth: '0 0.0625rem 0.0625rem 0.0625rem',
  borderStyle: 'solid',
  borderColor: theme.customColors.wrapperGrey,
  borderRadius: '0 0 0.25rem 0.25rem',
}));
const NotificationCenterEmptyMessage = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans SemiBold',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',

  textAlign: 'center',
  position: 'relative',
  top: '1.8125rem',

  color: theme.palette.common.white,
}));

const NotificationCenterGroup = styled(Box)(() => ({
  width: '28.125rem',
}));
const NotificationCenterView = styled(Box)(() => ({
  width: '28.125rem',
}));
const NotificationListTitle = styled(Typography)(() => ({
  width: '23.4375rem',
  height: '1.1875rem',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
}));
const NotificationListCount = styled(Typography)(() => ({}));
const NotificationAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
    '& .MuiCollapse-root.MuiCollapse-entered': {
      overflowY: 'scroll',
    },
    '&:last-child': {
      '& .MuiCollapse-root.MuiCollapse-entered': {
        borderBottom: `0.0625rem solid ${theme.customColors.wrapperGrey}`,
      },
    },
  },
  '&.MuiAccordion-root .MuiAccordionSummary-root': {
    borderTop: `0.0625rem solid ${theme.palette.common.white}`,
  },
  '&.MuiAccordion-root:last-child .MuiAccordionSummary-root': {
    borderBottom: `0.0625rem solid ${theme.customColors.wrapperGrey}`,
  },
  '&.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const NotificationListItem = styled(Box)(({ theme }) => ({
  width: '28.125rem',
  height: '5rem',
  position: 'relative',

  borderTop: `0.0625rem solid ${theme.customColors.wrapperGrey}`,
  boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.customColors.black,
}));
const NotificationListDescription = styled(Typography)(() => ({
  width: '26.125rem',
  height: '2.1875rem',
  padding: '0.625rem 0 0 0.625rem',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
}));
const DeleteButton = styled(Button)(() => ({
  position: 'absolute',
  left: '92.67%',
  right: '0.22%',
  top: '1.25%',
  bottom: '58.75%',
  minWidth: '0',
  marginRight: '1rem',
  cursor: 'pointer',
}));
const NotificationTime = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  height: '1rem',
  left: '0.625rem',
  right: '23.5rem',
  top: '3.3125rem',
  width: '10rem',

  fontFamily: 'Open Sans SemiBold',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '0.75rem',
  lineHeight: '1rem',

  color: theme.palette.border.lightWhiteGrey,
}));
const ExploreButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '22.5625rem',
  right: '0.8125rem',
  top: '2.5rem',
  bottom: '1rem',
  '& .MuiButtonBase-root.MuiButton-root': {
    minWidth: '4rem',
    height: '1.5rem',
  },
}));

const DataLoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: '20px',
  position: 'relative',
  left: '200px',
  top: '20px',
}));

export {
  NotificationCenter,
  NotificationCenterHeader,
  TitleFrame,
  NotificationCenterTitle,
  CategoryToggle,
  FunctionalButton,
  TimeButton,
  ExpandAndCollapseToggle,
  ExpandAndCollapsButton,
  NotificationCenterList,
  NotificationCenterEmptyList,
  NotificationCenterEmptyMessage,
  NotificationCenterGroup,
  NotificationCenterView,
  NotificationListTitle,
  NotificationAccordion,
  NotificationListItem,
  NotificationListDescription,
  DeleteButton,
  NotificationTime,
  ExploreButton,
  DataLoadingIndicator,
  NotificationListCount,
};
