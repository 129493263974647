import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { withRouter } from "react-router";
import { authenticationGuard, globalstate$, config } from "@smf/ui-util-app";
import { Box, CircularProgress, Typography } from "@mui/material";
import { fetchUserClaims } from "./helpers/tokenHelper";
import Header from "../../containers/Header";
import { redirectInApp } from "../../utils/helper";

const ID_TOKEN_KEY = "id_token";
const ACCESS_TOKEN_KEY = "access_token";

/**
 * This Custom HOC is responsible for teh following operations :
 *  1. Provide a Auth guard over the Child Component
 *  2. On component Mount, check the logged in User session from Util app
 *  3. Subscribe & Synchronize the logged in user flag and access token
 *     from the global state using observable
 *
 * @param {} props
 *
 * @author Amlan Saha
 *
 * @returns Render Cjild Component based on session flag
 */
const AuthenticatedRoute = (props) => {
  // is Authorized flag, having sync through global state subscription
  const [isAuthorized, setIsAuthorized] = useState(
    globalstate$.value.isLoggedInUser
  );
  // is loading flag, having sync through global state subscription
  const [isLoading, setIsLoading] = useState(true);
  const [hasCognitoScope, setHasCognitoScope] = useState(true);

  // On Component mount hook to update the states
  useEffect(() => {
    setToken();
  }, []);

  /**
   * This method is responsible to perform the followings:
   *  1. Call the {@AuthenticationService.isloggedInUser()}, to check the
   *      logged in User session from Util app
   *  2. Subscribe the Gobal State to sync the local state
   *
   * @author Amlan Saha
   */
  const setToken = async () => {
    try {
      // Initiate the call to the Util module centralize api to validate
      // the token in local storage,
      // if not present in local storage, it checks for the Access Token
      // in the Hash enabled redirect URL,
      // If found set it into the local storage
      // else, return false
      const sub = globalstate$;
      if (props.path === "/authorize") {
        const idToken = authenticationGuard.getTokenFromURL(ID_TOKEN_KEY);
        const accessToken =
          authenticationGuard.getTokenFromURL(ACCESS_TOKEN_KEY);
        localStorage.setItem("token", accessToken);
        if (idToken) {
          props.history.push(props.path);
          // Fetch User Roles using /authorizer/user API
          await fetchTokenWithUserScope(accessToken);
        }
      } else {
        const isLoggedInStatus = await authenticationGuard.isloggedInUser();
        if (isLoggedInStatus != undefined) {
          if (!isLoggedInStatus) {
            props.history.push("/login");
          } else {
            props.history.push(
              window.location.pathname?.replace(/\/$/, "") || props.path
            );
            // Subscribe to Global state
            sub.subscribe(async ({ sessionToken, isLoggedInUser }) => {
              authorizedUser(isLoggedInUser, sessionToken);
            });
            /**
             * Handle the redirection in the app
             */
            redirectInApp();
          }
        }
      }

      return () => {
        sub.unsubscribe();
      };
    } catch (err) {
      console.error("error in set token: ", err);
      setIsAuthorized(false);
      setIsLoading(true);
    }
  };

  /**
   * Fetch user scopes from User API and redirect to Cognito to get token with user scopes.
   * @param accessToken
   */
  async function fetchTokenWithUserScope(accessToken) {
    const userClaims = await fetchUserClaims(accessToken);
    const scopes = userClaims ? userClaims.scopes : [];
    if (scopes) {
      redirectToScope(scopes);
    } else {
      window.location = "/logout";
    }
  }

  const mapCiamGroupsToCognito = (claim) => {
    // List of synced groups in cognito from CIAM eg. "[a2333, b344]"
    const associatedGroups = claim.Value
      ? claim.Value.replace("[", "").replace("]", "").split(",")
      : []; // Array to store mapped groups for user

    const mappedGroups = []; // Object containing all the groups from Azure AD where key is group id and value is name

    const azureADMetaDataGroups = config.AZURE_AD_GROUP_METADATA;
    const cognitoScopePrefix = `https://${config.BASE_API_URL}/`;

    associatedGroups.forEach((group) => {
      // Trim each group to get rid of extra spaces before comma (,)
      const groupValue = group ? group.trim() : ""; // Populate the matching groups in response

      if (azureADMetaDataGroups[groupValue]) {
        mappedGroups.push(
          cognitoScopePrefix + azureADMetaDataGroups[groupValue]
        );
      }
    });

    return mappedGroups;
  };

  const authorizedUser = (isLoggedInUser, sessionToken) => {
    setIsAuthorized(isLoggedInUser);
    setIsLoading(sessionToken ? false : true);
  };

  const redirectToScope = (scope) => {
    let loginUrl = config.COGNITO_SIGN_IN_URL.replace(
      "${COGNITO_AUTH_URL}",
      config.COGNITO_AUTH_URL
    )
      .replace("${COGNITO_CLIENT_ID}", config.COGNITO_CLIENT_ID)
      .replace("${COGNITO_REDIRECT_URL}", config.COGNITO_REDIRECT_URL)
      .replace("response_type=token", "response_type=code");
    if (scope) {
      if (scope.length > 0) {
        scope.forEach((item) => {
          loginUrl += `+${item}`;
        });
      }
    }
    window.location = loginUrl;
  };

  // Condition based child route rendering
  return (
    <>
      {isLoading && (
        //Loader intil aythentication done
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <CircularProgress
            size={40}
            style={{ color: "#FFF", marginBottom: "20px" }}
            thickness={4}
          />
          <Typography variant="h4" style={{ color: "#FFF" }}>
            Fetching your role information...
          </Typography>
        </Box>
      )}
      {!isLoading &&
        (isAuthorized ? (
          hasCognitoScope ? (
            <Route {...props} />
          ) : (
            <Route path="/" component={Header} />
          )
        ) : (
          <Redirect to="/login" />
        ))}
    </>
  );
};
export default withRouter(AuthenticatedRoute);
