import React from 'react';
import { Box, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

export default function LoadingIndicator({ size }) {

    return (
        <>
            <Backdrop sx={{zIndex: (theme) => theme.zIndex.drawer - 1}} open >
                <Box sx={{color: (theme) => theme.palette.background.default, textAlign: 'center'}}>
                    <CircularProgress color='inherit' size={size} />
                </Box>
            </Backdrop>
        </>
    );
}

LoadingIndicator.propTypes = {
    size: PropTypes.number,
};
