import * as AWS from "@aws-sdk/client-cognito-identity-provider";
import { config, authenticationGuard } from "@smf/ui-util-app";

const client = new AWS.CognitoIdentityProvider({
  region: config.AWS_REGION,
});

/**
 * Fetch Paylod from JWT Token
 * @param {string} token
 * @returns {any} tokenPayload
 */
export const fetchUserClaims = async (token) => {
  const userClaims = await authenticationGuard.getAuthUserDetails({
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log("userClaims" + JSON.stringify(userClaims));
  return userClaims;
};
