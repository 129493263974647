import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import smfStyles from './globalStyles.js';
import Routes from "./containers/Routes";
import {theme} from '@smf/ui-util-app';
import moment from 'moment';
import './index.css';

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: function (input) {
      return input === 'now' ? input : input + ' ago';
    },
    s: 'now',
    ss: '%s secs',
    m: '1 min',
    mm: '%d min',
    h: '1 hr',
    hh: '%d hrs',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%dM',
    y: '1 year',
    yy: '%dY',
  },
});

export default function Root(props) {
  return (<>
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={smfStyles()} />
      <Routes/>
    </ThemeProvider>
    
  </>
  );
}
