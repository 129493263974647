import { forwardRef, React } from 'react';
import { TextField as MuiTextField, InputAdornment, styled } from '@mui/material';
import PropTypes from 'prop-types';
import ExclamationMark  from '../../assets/img/exclamationMark.svg';
import { MAX_INPUT_SIZE } from '../../constants';

const CustomTextField = styled(MuiTextField, {
		shouldForwardProp: (prop) => prop !== 'textAlign',
  	})(({ theme, textAlign }) => ({
		'& .Mui-disabled': {
			color: (props) =>
				props.disabledGrey
					? theme.palette.background.shuttleGray
					: theme.palette.text.primary,
			cursor: 'not-allowed',
		},
		'& label.Mui-focused': {
			color: theme.palette.text.primary,
		},
		'& .MuiOutlinedInput-input': {
			padding: '0.8em 0 0.8em 0.8em',
			color: theme.palette.text.primary,
		},
		'& .MuiTypography-colorTextSecondary': {
			color: theme.palette.text.primary,
		},
		'& .MuiFormHelperText-root.Mui-error': {
			color: theme.palette.text.errorText,
			fontSize: '0.75rem',
			marginLeft: '0.2em',
			minWidth: '100%',
		},
		'& .MuiFormHelperText-root': {
			color: theme.palette.text.primary,
			marginLeft: '0.2em',
		},
		'& .header-MuiOutlinedInput-root': {
			backgroundColor: theme.palette.background.blackGrey,
			height: '3em',
			'& fieldset': {
				borderColor: theme.palette.border.lightWhiteGrey,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.border.primary,
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.selected.greenSelect,
			},
			'&.Mui-disabled fieldset': {
				borderColor: theme.palette.border.lightWhiteGrey,
			},
		},

		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.selected.greenSelect,
			}
		}
	})
);

const TextField = forwardRef((props, ref) => {
	const {
		value,
		variant,
		error,
		helperText,
		type,
		onChange,
		suffixText = '',
		maxLen = MAX_INPUT_SIZE,
		placeholder,
		customInputProps = {},
		fullWidth = true,
		disabledGrey = false,
		showErrorIcon = true,
		...other
	} = props;

	return (
		<CustomTextField
			value={value}
			error={error || false}
			helperText={helperText || ''}
			variant={variant || 'outlined'}
			type={type || 'text'}
			onChange={onChange}
			fullWidth={fullWidth}
			InputProps={{
				endAdornment:
					error && showErrorIcon ? (
						<ExclamationMark />
					) : (
						<InputAdornment position='end'>{suffixText}</InputAdornment>
					),
			}}
			// eslint-disable-next-line react/jsx-no-duplicate-props
			inputProps={{
				maxLength: maxLen,
				form: {
					autocomplete: 'off',
				},
				...customInputProps,
			}}
			placeholder={placeholder}
			autoComplete='no'
			ref={ref}
			{...other}
		/>
	);
});

TextField.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	variant: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	type: PropTypes.string,
	suffixText: PropTypes.string,
	maxLen: PropTypes.number,
	placeholder: PropTypes.string,
	customInputProps: PropTypes.shape({}),
	fullWidth: PropTypes.bool,
	disabledGrey: PropTypes.bool,
	showErrorIcon: PropTypes.bool,
};

export default TextField;
