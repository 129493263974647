import React, { useState, useEffect } from "react";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@smf/ui-util-app';

import Header from '../../components/Header';
import { ToastProvider } from "../../context/toastContext";
import ReactQueryProvider from '../../context/react-query.provider';
import { useRxjsState } from "../../utils/hooks/useRxjsState";
import LoadingIndicator from '../../components/LoadingIndicator';

export default function Root(props) {

  const { rxjsState } = useRxjsState();

  const data = {
    userName: rxjsState.userInformation?.userName || '',
    userRole: rxjsState.userInformation?.userRole || { permission: 'System Admin', level: 'GLOBAL' },
    userStatus: rxjsState?.userStatus || 110,
    companyDetails: { companyName: '', companyId: rxjsState.companyData?.companyId },
    loggedUserEmailId: rxjsState.userInformation?.loggedUserEmailId || ''
  }
  return (<>
    {
      rxjsState.isLoggedInUser ?
        <ThemeProvider theme={theme}>
          <ReactQueryProvider>
            <ToastProvider>
              <Header {...data} />
            </ToastProvider>
          </ReactQueryProvider>
        </ThemeProvider>
      : <ThemeProvider theme={theme}><div style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 9000000000000, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><LoadingIndicator /></div></ThemeProvider>
    }
  </>
  );
}
