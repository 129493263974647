import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useParams,
} from "react-router-dom";
import { useMemo,useCallback,useEffect } from "react";
import Header from "../../containers/Header";
import AuthenticatedRoutes from "../AuthenticatedRoute";
import { config, globalstate$ } from "@smf/ui-util-app";


/**
 * This is the React Route Configuration for
 * Globe Micro App.
 *
 * 1. '/login' --> takes the control to Cognito
 * 2. '/'      --> Root Route for Globe App
 *
 * @author Amlan Saha
 *
 * @Return  React Router Config for Globe App
 */

const Routes = () => {
  const toBeIgnoredKeys = useMemo(() => {
    return new Set([
      "accessTokenAddedTime",
      "expires_in",
      "idToken",
      "isExtendedLogin",
      "isLoggedInUser",
      "refreshToken",
      "sessionToken",
      "userData",
      "userStatus",
      "websocketConnection",
    ]);
  }, []);

  const toBeIgnoredPaths = useMemo(() => {
    return new Set([
      /**
       * Ignoring home page as well, because during login the session store getting upadted
       */
      "/",
      "/login",
      "/logout",
      "/error",
      "/authorize"
    ]);
  }, []);

  const getStateSnapshot = useCallback(() => {
    const snap = {};

    Object.entries(globalstate$.getValue()).forEach(([key, value]) => {
      if (!toBeIgnoredKeys.has(key)) {
        snap[key] = value;
      }
    });

    return snap;
  }, [toBeIgnoredKeys]);

  const handlePathChange = useCallback(() => {
    const { pathname, search } = window.location;
    /**
     * Ignoring redirect setup if its an auth path
     */
    if (toBeIgnoredPaths.has(pathname)) return;

    /**
     * setting the session storage
     */
    sessionStorage.setItem(
      "redirectTo",
      JSON.stringify({
        callbackPath: pathname,
        stateSnap: getStateSnapshot(),
      })
    );
  }, [getStateSnapshot, toBeIgnoredPaths]);

  /**
   * side effect to monitor path change
   */
  useEffect(() => {
    window.addEventListener("popstate", handlePathChange);
    return () => window.removeEventListener("popstate", handlePathChange);
  }, [handlePathChange]);

  return (
    <Router>
      <Switch>
        {/* Cognito Login Redirect route */}
        <Route
          exact
          path="/login"
          component={() => {
            window.location = config.COGNITO_SIGN_IN_URL.replace(
              "${COGNITO_AUTH_URL}",
              config.COGNITO_AUTH_URL
            )
              .replace("${COGNITO_CLIENT_ID}", config.COGNITO_CLIENT_ID)
              .replace(
                "${COGNITO_REDIRECT_URL}",
                config.NEW_APP_URL + "/authorize"
              );
            return null;
          }}
        />

        {/* Cognito logout Redirect route */}
        <Route
          exact
          path="/logout"
          component={() => {
            /**
             * clearing session store
             */
            sessionStorage.clear();
            window.location = config.COGNITO_SIGN_OUT_URL.replace(
              "${COGNITO_AUTH_URL}",
              config.COGNITO_AUTH_URL
            )
              .replace("${COGNITO_CLIENT_ID}", config.COGNITO_CLIENT_ID)
              .replace(
                "${COGNITO_REDIRECT_URL}",
                config.NEW_APP_URL + "/authorize"
              );
            return null;
          }}
        />

        {/* Load user scope Route */}
        <AuthenticatedRoutes exact path="/authorize" component={Header} />

        {/* Globe Root Route */}
        <AuthenticatedRoutes path="/" component={Header} />

        {/* Fallback Route */}
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default Routes;
