import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const AlertContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ theme, isPush }) => ({
  width: '28.125rem',
  minHeight: isPush ? '5rem' : '6.25rem',
  background: theme.customColors.black,
  boxShadow: '0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25rem',
  borderWidth: '0.0625rem',
  borderStyle: 'solid',
  borderColor: theme.customColors.wrapperGrey,
}));

const AlertHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ isPush }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginTop: '0.5rem',
  marginBottom: isPush ? 0 : '1rem',
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

const IconContainer = styled(Box)(() => ({
  margin: '0 0.5rem 0 0.5rem',
}));

const NotificationMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: theme.customColors.white,
}));

const TimestampContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '5rem',
  '&:hover #timestamp-value': {
    display: 'none',
  },
  '&:hover #close-icon': {
    display: 'inline-block',
  },
}));

const Timestamp = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ theme, isPush }) => ({
  display: 'flex',
  justifyContent: isPush ? 'flex-end' : 'flex-start',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.customColors.nobelGrey,
  width: '4rem',
  marginRight: isPush ? '1rem' : 0,
  marginLeft: isPush ? 0 : '2.5rem',
}));

const AlertContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ isPush }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: isPush ? 'flex-end' : 'center',
  marginBottom: '0.5rem',
}));

const FunctionalAreaName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ theme, isPush }) => ({
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.palette.text.lightWhiteGrey,
  marginLeft: isPush ? '2.5rem' : 0,
}));

const AlertActions = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginRight: '1rem',
  '& .MuiButtonBase-root.MuiButton-root': {
    minWidth: '4rem',
    height: '1.5rem',
  },
}));

const CloseIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPush',
})(({ isPush }) => ({
  display: isPush ? 'none' : 'inline-block',
  marginRight: '1rem',
  cursor: 'pointer',
}));

const NotificationInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const PushAlertTimestamp = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const SnoozedTimestamp = styled('span')(({ theme }) => ({
  paddingLeft: '0.4rem',
}));

export {
  AlertContainer,
  AlertHeader,
  StyledBox,
  IconContainer,
  NotificationMessage,
  Timestamp,
  AlertContent,
  FunctionalAreaName,
  AlertActions,
  TimestampContainer,
  CloseIconContainer,
  NotificationInfo,
  PushAlertTimestamp,
  SnoozedTimestamp,
};
