import { React, useState, useEffect, memo } from 'react';
import { Snackbar, Slide, Typography, Box, alpha, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { TOAST_TYPE } from '../../constants';
import ErrorIcon from '../../assets/img/dangerIconV2.svg';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import SuccessIcon from '../../assets/img/successIconV2.svg';
import InfoIcon from '../../assets/img/infoIconV2.svg';
import MESSAGE_STRINGS from '../../constants/en-us';

const colorType = {
	[TOAST_TYPE.ERROR]: 'cocoaToneRed',
	[TOAST_TYPE.WARNING]: 'bronzeTone',
	[TOAST_TYPE.SUCCESS]: 'blackOliveGreen',
	[TOAST_TYPE.INFO]: 'nileBlue',
};

const borderColors = {
	[TOAST_TYPE.ERROR]: 'lavaRed',
	[TOAST_TYPE.WARNING]: 'tangerineColor',
	[TOAST_TYPE.SUCCESS]: 'saveGreen',
	[TOAST_TYPE.INFO]: 'highlightBlue',
};

const iconMap = {
	[TOAST_TYPE.ERROR]: ErrorIcon,
	[TOAST_TYPE.WARNING]: WarningIcon,
	[TOAST_TYPE.SUCCESS]: SuccessIcon,
	[TOAST_TYPE.INFO]: InfoIcon,
};

const CustomSnackbar = styled(Snackbar, {
	shouldForwardProp: (prop) => prop !== 'key' && prop !== 'resultColor',
  })(({ resultColor, theme }) => {
	
	const ToastColor = colorType[resultColor] || colorType[TOAST_TYPE.ERROR];
	const ToastBorderColor = borderColors[resultColor] || borderColors[TOAST_TYPE.ERROR];

	return {
	  	zIndex: theme.zIndex.modal + 1,
	  	'.MuiPaper-root': {
			minWidth: '28.5rem',
			background: alpha(theme.palette.background[ToastColor], 0.95),
			border: `1px solid ${ theme.palette.border[ToastBorderColor]}`,
			borderRadius: '0.35rem',
			padding: 0
		},
	  	'.MuiSnackbarContent-message': {
			width: '100%',
			padding: 0
		},
	};
  });

let timeout;

function ShowSnackBar({ toastType = TOAST_TYPE.ERROR, message = '' }) {
	const Icon = iconMap[toastType];

	return (
		<Box display='flex'>
			<Box
				display='flex'
				justify='center'
				alignItems='center'
				width='100%'
				marginY='1rem'
			>
				<Box marginLeft='1.75rem' marginRight='1.25rem'>
					<img src={Icon} alt='Icon' />
				</Box>
				<Box marginRight='1.25rem'>
					<Typography variant='subtitle1'>
						{message || MESSAGE_STRINGS[`Toast.message.${toastType}`]}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

function Toast({ type, message }) {
	const [open, setOpen] = useState(false);
	const [toastType, setToastType] = useState('');

	useEffect(() => {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			setOpen(false);
		}, 4000);
		setOpen(true);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const SlideTransitionComponent = memo((props) => (
		<Slide {...props} direction="right" />
	  ));
	
	  SlideTransitionComponent.displayName = 'SlideTransition';

	return (
		<CustomSnackbar
			open={open}
			TransitionComponent={SlideTransitionComponent}
			message={<ShowSnackBar toastType={type} message={message} />}
			key={type}
			resultColor={type}
			anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
		/>
	);
}

ShowSnackBar.propTypes = {
	toastType: PropTypes.string,
	message: PropTypes.string,
};
Toast.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string,
};
export default Toast;