import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '../../assets/img/closeIcon.svg';
import NotificationCenterAccordion from '../../components/Accordion/';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import MESSAGE_STRINGS from '../../constants/en-us';
import { NOTIFICATION_TYPES } from '../../constants';
import {
  NotificationCenter,
  NotificationCenterHeader,
  TitleFrame,
  NotificationCenterTitle,
  CategoryToggle,
  FunctionalButton,
  TimeButton,
  ExpandAndCollapseToggle,
  ExpandAndCollapsButton,
  NotificationCenterList,
  NotificationCenterEmptyList,
  NotificationCenterEmptyMessage,
  NotificationCenterGroup,
  NotificationCenterView,
  NotificationListTitle,
  NotificationListItem,
  NotificationListDescription,
  DeleteButton,
  NotificationTime,
  ExploreButton,
  DataLoadingIndicator,
  NotificationListCount,
  NotificationAccordion,
} from './style.js';
import { groupBy } from 'lodash';
import { handleExploreNavigation } from '../../utils/helper';
import moment from 'moment';

function NotificationCenterModal({
  closeModal,
  isLoadingNotificationData,
  activeNotifications,
  dismissNotification,
}) {
  const [isExpandedAll, setIsExpandedAll] = useState(false);
  const [activeTriggersData, setActiveTriggersData] = useState([]);
  const [functionalAreaNotifications, setFunctionalAreaNotifications] =
    useState([]);
  const [selectedCategory, setSelectedCategory] = useState(MESSAGE_STRINGS['NotificationCenter.Category.Time']);
  const buttonRef = useRef();

  const sortNotificationByType = (notifications) => {
    const snoozedAlerts = [];
    const nonSnoozedAlerts = [];
    const nonAlertNotifications = [];
    notifications.forEach(notification => {
      if (notification.notificationType === NOTIFICATION_TYPES.ALERT && notification.isSnoozed) {
        snoozedAlerts.push(notification);
      } 
      else if (notification.notificationType === NOTIFICATION_TYPES.ALERT && !notification.isSnoozed) {
        nonSnoozedAlerts.push(notification);
      }
      else {
        nonAlertNotifications.push(notification);
      }
    });
    const sortedSnoozedAlerts = snoozedAlerts.sort((a,b) => a.snoozeStartTime - b.snoozeStartTime);
    const sortedNotifications = [...sortedSnoozedAlerts, ...nonSnoozedAlerts, ...nonAlertNotifications];
    return sortedNotifications;
  }

  useEffect(() => {
    if (activeNotifications?.length) {
      const sortedNotifications = sortNotificationByType(activeNotifications);
      setActiveTriggersData(sortedNotifications);

      setFunctionalAreaNotifications(
        getNotificationsByFunctionalArea(sortedNotifications)
      );
    }
    return () => {
      setActiveTriggersData([]);
    };
  }, [activeNotifications]);

  const handleExpandCollapse = (expanded) => {
    setIsExpandedAll(!isExpandedAll);
    const updatedData = functionalAreaNotifications.map((item) => ({
      ...item,
      isExpanded: expanded
    }));
    setFunctionalAreaNotifications(updatedData);
  };

  const handleExpandCollapseFunctionalArea = (panel) => {
    const updatedData = functionalAreaNotifications.map((item) => {
      if (item.functionalAreaName === panel) {
        return { ...item, isExpanded: !item.isExpanded };
      }
      return item;
    });
    // Enabling/disabling Expand All/Collapse All buttons
    if (updatedData.every((item) => item.isExpanded === true)) {
      setIsExpandedAll(true);
    } else if (updatedData.every((item) => item.isExpanded === false)) {
      setIsExpandedAll(false);
    }
    setFunctionalAreaNotifications(updatedData);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.textContent);
  };

  const handleDismiss = (uuid) => {
    dismissNotification(uuid);
  };

  const handleExplore = (uuid, functionalArea) => {
    handleDismiss(uuid);
    closeModal();
    handleExploreNavigation(functionalArea);
  };

  const getNotificationsByFunctionalArea = (activeNotifications) => {
    const orderedNotifications = [];
    const groupedNotifications = groupBy(
      activeNotifications,
      'functionalAreaName'
    );
    // Sorted functional areas
    const ORDERED_FUNCTIONAL_AREAS = [
      'Factory Health',
      'Overall Equipment Effectiveness',
      'Equipment Constraint',
      'Standard Operating Conditions',
      'Role Based Access Control',
    ];
    // Getting alphabetically ordered list of notifications by functional area
    ORDERED_FUNCTIONAL_AREAS.forEach((name) => {
      const group =
        groupedNotifications &&
        Object.entries(groupedNotifications).find(([key]) => key === name);
      group &&
        orderedNotifications.push({
          functionalAreaName: group[0],
          notifications: group[1],
          isExpanded: false,
        });
    });
    return orderedNotifications;
  };

  const getFunctionalAreaAccordions = () => {
    return functionalAreaNotifications.map(
      ({ functionalAreaName, notifications, isExpanded }) => {
        return (
          <NotificationAccordion
            expanded={isExpanded}
            style={{
              border: 'none',
            }}
            key={functionalAreaName}
            onChange={() =>
              handleExpandCollapseFunctionalArea(functionalAreaName)
            }
            data-testid={`${functionalAreaName}-accordion`}
          >
            <NotificationCenterAccordion.AccordionSummary             
              data-testid={`${functionalAreaName}-accordion-summary`}
            >
              <NotificationListTitle>
                {functionalAreaName}
              </NotificationListTitle>
              <NotificationListCount>
                {notifications.length}
              </NotificationListCount>
            </NotificationCenterAccordion.AccordionSummary>
            <NotificationCenterAccordion.AccordionDetails>
              {notifications && getNotificationList(notifications)}
            </NotificationCenterAccordion.AccordionDetails>
          </NotificationAccordion>
        );
      }
    );
  };

  const getNotificationList = (notificationData) => {
    return notificationData.map((notification) => {
      if (notification.notificationType === NOTIFICATION_TYPES.NC_ONLY) {
        return (
          <NotificationListItem key={notification.key}>
            <NotificationListDescription>
              {notification.triggerName}
            </NotificationListDescription>
            <DeleteButton
              onClick={() => {
                handleDismiss(notification.uuid);
              }}
            >
              <img src={CloseIcon} alt="closeIcon" />
            </DeleteButton>
            <NotificationTime>
              {moment(parseInt(notification.createdTimeStamp)).fromNow()}
            </NotificationTime>
            <ExploreButton>
              <Button
                type="secondary"
                onClick={() => {
                  handleExplore(
                    notification.uuid,
                    notification.functionalAreaName
                  );
                }}
                text={MESSAGE_STRINGS['Alert.Explore']}
                data-testid="nc-explore-button"
                size="small"
              />
            </ExploreButton>
          </NotificationListItem>
        );
      } else if (notification.notificationType === NOTIFICATION_TYPES.ALERT) {
        return (
          <Alert
            key={notification.uuid}
            notificationData={notification}
            dismissNotification={() => {
              handleDismiss(notification.uuid);
            }}
            type={NOTIFICATION_TYPES.NC_ONLY}
          />
        );
      }
    });
  };

  return (
    <NotificationCenter>
      {/* below 2 spans are for the triangle tip of the modal */}
      <span
        style={{
          content: ' ',
          boxSizing: 'border-box',
          position: 'absolute',
          right: '4.1875rem',
          top: '-2.1875rem',
          border: 'solid transparent',
          borderBottomColor: '#40444A',
          borderWidth: '1.125rem',
          marginLeft: '-0.3125rem',
          boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        }}
      ></span>
      <span
        style={{
          content: ' ',
          boxSizing: 'border-box',
          position: 'absolute',
          right: '4.25rem',
          top: '-2rem',
          border: 'solid transparent',
          borderBottomColor: 'black',
          borderWidth: '1.0625rem',
          zIndex: '1000',
          marginLeft: '-0.3125rem',
          boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        }}
      ></span>
      <NotificationCenterHeader>
        <TitleFrame>
          <NotificationCenterTitle>
            {MESSAGE_STRINGS['NotificationCenter.Title']}
          </NotificationCenterTitle>
        </TitleFrame>
        <CategoryToggle>
          <TimeButton
            onClick={handleCategoryChange}
            isSelected={
              selectedCategory ===
              MESSAGE_STRINGS['NotificationCenter.Category.Time']
            }
          >
            {MESSAGE_STRINGS['NotificationCenter.Category.Time']}
          </TimeButton>
          <FunctionalButton
            onClick={handleCategoryChange}
            isSelected={
              selectedCategory ===
              MESSAGE_STRINGS['NotificationCenter.Category.FunctionalArea']
            }
          >
            {MESSAGE_STRINGS['NotificationCenter.Category.FunctionalArea']}
          </FunctionalButton>
        </CategoryToggle>
        {selectedCategory ===
          MESSAGE_STRINGS['NotificationCenter.Category.FunctionalArea'] && (
          <ExpandAndCollapseToggle ref={buttonRef}>
            <ExpandAndCollapsButton
              id="Expand"
              onClick={() => handleExpandCollapse(true)}
              disabled={isExpandedAll}
              data-testid='expand-all-button'
            >
            {MESSAGE_STRINGS['NotificationCenter.Notification.ExpandAll']}
            </ExpandAndCollapsButton>
            <hr />
            <ExpandAndCollapsButton
              id="Collapse"
              onClick={() => handleExpandCollapse(false)}
              disabled={!isExpandedAll}
              data-testid='collapse-all-button'
            >
            {MESSAGE_STRINGS['NotificationCenter.Notification.CollapseAll']}
            </ExpandAndCollapsButton>
          </ExpandAndCollapseToggle>
        )}
      </NotificationCenterHeader>
      {/* if there is data, then show below ui */}
      {!isLoadingNotificationData ? (
        activeTriggersData.length ? (
          <NotificationCenterList>
            <NotificationCenterGroup>
              <NotificationCenterView data-testid='notification-container'>
                {selectedCategory ===
                  MESSAGE_STRINGS['NotificationCenter.Category.Time'] &&
                  activeTriggersData &&
                  getNotificationList(activeTriggersData)}
                {selectedCategory ===
                  MESSAGE_STRINGS[
                    'NotificationCenter.Category.FunctionalArea'
                  ] && getFunctionalAreaAccordions()}
              </NotificationCenterView>
            </NotificationCenterGroup>
          </NotificationCenterList>
        ) : (
          <NotificationCenterEmptyList>
            <NotificationCenterEmptyMessage>
              {MESSAGE_STRINGS['NotificationCenter.EmptyNotification']}
            </NotificationCenterEmptyMessage>
          </NotificationCenterEmptyList>
        )
      ) : (
        <NotificationCenterEmptyList>
          <DataLoadingIndicator size={40} thickness={4} />
        </NotificationCenterEmptyList>
      )}
    </NotificationCenter>
  );
}

export default NotificationCenterModal;
