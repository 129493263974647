import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import AlertIcon from '../../assets/img/blueExclamationIcon.svg';
import CloseIcon from '../../assets/img/closeIcon.svg';
import AlarmClock from '../../assets/img/alarm.svg';
import { handleExploreNavigation } from '../../utils/helper';
import MESSAGE_STRINGS from '../../constants/en-us/index';
import { NOTIFICATION_TYPES } from '../../constants';
import moment from 'moment';
import {
  AlertContainer,
  AlertHeader,
  StyledBox,
  IconContainer,
  NotificationMessage,
  Timestamp,
  AlertContent,
  FunctionalAreaName,
  AlertActions,
  TimestampContainer,
  CloseIconContainer,
  NotificationInfo,
  PushAlertTimestamp,
  SnoozedTimestamp,
} from './style';

const Alert = ({
  notificationData,
  dismissNotification,
  snoozeNotification,
  notificationType = NOTIFICATION_TYPES.NC_ONLY,
}) => {
  const [isPush, setIsPush] = useState(''); // ? Push Notification or Notification Center Notification
  const [timeElapsed, setTimeElapsed] = useState(
    moment(
      parseInt(notificationData?.createdTimeStamp ?? moment.now())
    ).fromNow()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed(
        moment(parseInt(notificationData?.createdTimeStamp)).fromNow()
      );
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [notificationData?.createdTimeStamp]);

  useEffect(() => {
    setIsPush(notificationType === NOTIFICATION_TYPES.ALERT);
  }, [notificationType]);

  const handleDismiss = (uuid) => {
    dismissNotification(uuid);
  };

  const handleExplore = (uuid, functionalArea) => {
    handleDismiss(uuid);
    handleExploreNavigation(functionalArea);
  };

  const getTimestamp = () => {
    return (
      <Timestamp isPush={isPush} data-testid="timestamp-alert">
        {notificationData.isSnoozed ? (
          <PushAlertTimestamp>
            <img src={AlarmClock} alt="alarmClock" />
            <SnoozedTimestamp>
              {MESSAGE_STRINGS['Alert.Snoozed']}
            </SnoozedTimestamp>
          </PushAlertTimestamp>
        ) : (
          moment(parseInt(notificationData.createdTimeStamp)).fromNow()
        )}
      </Timestamp>
    );
  };

  return (
    <AlertContainer isPush={isPush} data-testid="alert-container">
      <AlertHeader isPush={isPush}>
        <StyledBox>
          <IconContainer>
            <img src={AlertIcon} alt="alertIcon" />
          </IconContainer>
          <NotificationInfo>
            <NotificationMessage>
              {notificationData.triggerName}
            </NotificationMessage>
            {!isPush && (
              <FunctionalAreaName isPush={isPush}>
                {notificationData.functionalAreaName}
              </FunctionalAreaName>
            )}
          </NotificationInfo>
        </StyledBox>
        {isPush && (
          <TimestampContainer>
            <Timestamp id="timestamp-value" isPush={isPush}>
              {timeElapsed}
            </Timestamp>
            <CloseIconContainer
              id="close-icon"
              onClick={() => {
                handleDismiss(notificationData.uuid);
              }}
              isPush={isPush}
              data-testid="close-button"
            >
              <img src={CloseIcon} alt="closeIcon" />
            </CloseIconContainer>
          </TimestampContainer>
        )}
        {!isPush && (
          <CloseIconContainer
            id="close-icon"
            onClick={() => {
              handleDismiss(notificationData.uuid);
            }}
            isPush={isPush}
            data-testid="close-button"
          >
            <img src={CloseIcon} alt="closeIcon" />
          </CloseIconContainer>
        )}
      </AlertHeader>
      <AlertContent isPush={isPush}>
        {isPush && (
          <FunctionalAreaName isPush={isPush}>
            {notificationData.functionalAreaName}
          </FunctionalAreaName>
        )}
        {!isPush && getTimestamp()}
        <AlertActions>
          {isPush && (
            <Button
              type="secondary"
              onClick={snoozeNotification}
              text={MESSAGE_STRINGS['Alert.Snooze']}
              data-testid="alert-snooze-button"
              size="small"
            />
          )}
          {notificationData?.isExploreButtonEnabled && (
            <Button
              type="secondary"
              onClick={() => {
                handleExplore(
                  notificationData.uuid,
                  notificationData.functionalAreaName
                );
              }}
              text={MESSAGE_STRINGS['Alert.Explore']}
              data-testid="alert-explore-button"
              size="small"
            />
          )}
        </AlertActions>
      </AlertContent>
    </AlertContainer>
  );
};

Alert.propTypes = {
  notificationData: PropTypes.object,
  dismissNotification: PropTypes.func,
  snoozeNotification: PropTypes.func,
  notificationType: PropTypes.string,
};

export default Alert;
