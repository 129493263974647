/* eslint-disable prettier/prettier */
import moment from 'moment';
import {
    NOTIFICATION_REDUCER_CONSTANTS,
    NOTIFICATION_TYPES,
} from '../constants';

const parseNotification = ({
    uuid,
    UUID,
    notificationId,
    triggerId,
    triggerName,
    createdAt = Date.now().toString(),
    notificationType,
    functionalAreaId,
    functionalAreaName,
    isAlertDismissed = false,
    recurrence = false,
    recurrenceValue,
    recurrenceUnit,
    snoozeDuration,
    snoozeDurationUoM,
    snoozeStartTime,
    isRead = false,
    isExploreButtonEnabled = true,
} = {}) => {
    return {
        key: `${uuid || UUID}_${triggerId}_${notificationId}`,
        uuid: uuid || UUID,
        notificationId,
        triggerId,
        triggerName,
        notificationType,
        functionalAreaId,
        functionalAreaName,
        createdAt: moment(parseInt(createdAt)).fromNow(),
        createdTimeStamp: parseInt(createdAt),
        isAlertDismissed,
        recurrence,
        recurrenceValue,
        recurrenceUnit,
        snoozeDuration,
        snoozeDurationUoM,
        snoozeStartTime: snoozeStartTime,
        isSnoozed: !!isAlertSnoozed(snoozeStartTime, snoozeDuration, snoozeDurationUoM),
        isRead,
        isExploreButtonEnabled,
    };
};

const isAlertSnoozed = (snoozeStartTime, snoozeDuration, snoozeDurationUoM) => {
    if (!snoozeStartTime || !snoozeDuration || !snoozeDurationUoM) return false;
    const timeDiff = moment(Date.now()).diff(moment(parseInt(snoozeStartTime)), snoozeDurationUoM.toLowerCase());
    return (timeDiff < snoozeDuration); 
}

const getClearedRecurrentIntervals = (existingRecurrenceAlertIntervals, uuid) => {
    const recurrentAlert = existingRecurrenceAlertIntervals.find(
        (interval) => interval.uuid === uuid
    );
    if (recurrentAlert?.intervalId) {
        clearInterval(recurrentAlert.intervalId);
    }
    return existingRecurrenceAlertIntervals.filter(alert => alert.uuid !== uuid);
}

const getClearedSnoozeTimeouts = (existingSnoozedAlertTimeouts, uuid) => {
    const snoozedAlert = existingSnoozedAlertTimeouts.find(
        (interval) => interval.uuid === uuid
    );
    if (snoozedAlert?.timeoutId) {
        clearTimeout(snoozedAlert.timeoutId);
    }
    return existingSnoozedAlertTimeouts.filter(alert => alert.uuid !== uuid);
}

const getUnreadNotificationCount = (notifications) => {
    const unreadNotifications = notifications.filter(
        (notification) => !notification?.isRead && !notification?.isSnoozed);
    return unreadNotifications.length;
};

export const initialNotificationsState = {
    count: 0,
    notifications: [], // master list of notifications displayed in notification center
    alerts: [], // list of alerts shown as push notifications
    recurrentAlertIntervals: [], // timer intervals for each recurring alert
    snoozedAlertIntervals: [], // timer intervals for each snoozed alert
    showNotificationCenter: false,
    showPushNotifications: true,
};

export const notificationsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case NOTIFICATION_REDUCER_CONSTANTS.DISMISS: {
            const { uuid } = payload;
            const updatedNotifications = [...state.notifications].filter(
                (notification) => notification.uuid !== uuid
            );
            const updatedAlerts = updatedNotifications.filter(
                (notification) =>
                    notification.notificationType === NOTIFICATION_TYPES.ALERT && !notification.isRead
            );
            const updatedRecurrenceAlertIntervals = getClearedRecurrentIntervals([...state.recurrentAlertIntervals], uuid);
            const updatedSnoozedAlertIntervals = getClearedSnoozeTimeouts([...state.snoozedAlertIntervals], uuid);
            const unreadCount = getUnreadNotificationCount(updatedNotifications);
            return {
                ...state,
                notifications: [...updatedNotifications],
                alerts: [...updatedAlerts],
                recurrentAlertIntervals: [...updatedRecurrenceAlertIntervals],
                snoozedAlertIntervals: [...updatedSnoozedAlertIntervals],
                count: unreadCount,
            };
        }
        case NOTIFICATION_REDUCER_CONSTANTS.DISMISS_PUSH_NOTIFICATION: {
            const { uuid, notificationType } = payload;
            let updatedAlerts = [...state.alerts];
            const updatedNotifications = [...state.notifications].map(
                (notification) => {
                    if (notification.uuid === uuid) {
                        return {
                            ...notification,
                            isAlertDismissed: true,
                            isRead: true,
                        };
                    }
                    return notification;
                }
            );
            if (notificationType === NOTIFICATION_TYPES.ALERT) {
                updatedAlerts = [...state.alerts].filter(
                    (alert) => alert.uuid !== uuid
                );
            }
            const updatedRecurrenceAlertIntervals = getClearedRecurrentIntervals([...state.recurrentAlertIntervals], uuid);
            const unreadCount = getUnreadNotificationCount(updatedNotifications);
            return {
                ...state,
                notifications: [...updatedNotifications],
                alerts: [...updatedAlerts],
                recurrentAlertIntervals: [...updatedRecurrenceAlertIntervals],
                count: unreadCount,
            };
        }
        case NOTIFICATION_REDUCER_CONSTANTS.INITIALIZE: {
            const formattedNotifications = payload.notifications
                .map((notification) => parseNotification(notification))
                .sort((a, b) => b.createdTimeStamp - a.createdTimeStamp);

            const formattedAlerts = formattedNotifications.filter(
                (notification) =>
                    notification.notificationType === NOTIFICATION_TYPES.ALERT && !notification.isAlertDismissed && !notification.isRead
            );
            const unreadCount = getUnreadNotificationCount(formattedNotifications);
            return {
                ...state,
                notifications: [...formattedNotifications],
                alerts: [...formattedAlerts],
                count: unreadCount,
            };
        }
        case NOTIFICATION_REDUCER_CONSTANTS.APPEND: {
            const newNotification = parseNotification(payload.notification);
            const existingNotifications = [...state.notifications];
            const existingAlerts = [...state.alerts];
            const isUnread = !newNotification.isRead; 
            if (
                existingNotifications.findIndex(
                    (existing) => existing.uuid === newNotification.uuid
                ) > -1
            ) {
                return {
                    ...state,
                };
            } else {
                return {
                    ...state,
                    notifications: [newNotification, ...existingNotifications],
                    alerts:
                        newNotification.notificationType === NOTIFICATION_TYPES.ALERT && !newNotification.isAlertDismissed && isUnread
                            ? [newNotification, ...existingAlerts]
                            : [...existingAlerts],
                    count: isUnread ? state.count + 1 : state.count,
                };
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.UPDATE_RECURRENCE_INTERVAL: {
            const { uuid, intervalId } = payload;
            const existingRecurrenceAlertIntervals = [...state.recurrentAlertIntervals];
            if (intervalId !== null) {
                return {
                    ...state,
                    recurrentAlertIntervals: [{ uuid, intervalId }, ...existingRecurrenceAlertIntervals]
                }
            }
            return {
                ...state,
                recurrentAlertIntervals: existingRecurrenceAlertIntervals.filter(alert => alert.uuid !== uuid)
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.UPDATE_SNOOZE_TIMER: {
            const { uuid, timeoutId } = payload;
            const existingSnoozedAlertIntervals = [...state.snoozedAlertIntervals];

            return {
                ...state,
                snoozedAlertIntervals: [{ uuid, timeoutId }, ...existingSnoozedAlertIntervals]
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.APPEND_EXISTING_ALERT: {
            const { notification, isSnoozeAction } = payload;
            let existingAlerts = [...state.alerts];
            let updatedNotifications = [...state.notifications];
            updatedNotifications = [...updatedNotifications].map(
                (existing) => {
                    if (existing.uuid === notification.uuid) {
                        return {
                            ...notification,
                        };
                    }
                    return {...existing};
                }
            );
            const unreadCount = getUnreadNotificationCount(updatedNotifications);
            if (isSnoozeAction) {
                existingAlerts = [...existingAlerts].filter(alert => alert.uuid !== notification.uuid);
                return {
                    ...state,
                    alerts: [{ ...notification }, ...existingAlerts],
                    notifications: [...updatedNotifications],
                    snoozedAlertIntervals: [...state.snoozedAlertIntervals].filter(interval => interval.uuid !== notification.uuid),
                    count: unreadCount,
                }
            }
            return {
                ...state,
                alerts: [{ ...notification }, ...existingAlerts],
                notifications: [...updatedNotifications],
                count: unreadCount,
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.MARK_AS_SNOOZED: {
            const { uuid, snoozeStartTime } = payload;
            const updatedAlerts = [...state.alerts].map((alert) => {
                if (alert.uuid === uuid) {
                    return { ...alert, isSnoozed: true, snoozeStartTime, isRead: true}
                }
                return { ...alert }
            });

            const updatedNotifications = [...state.notifications].map(
                (notification) => {
                    if (notification.uuid === uuid) {
                        return {
                            ...notification,
                            isSnoozed: true,
                            snoozeStartTime,
                            isRead: true,
                        };
                    }
                    return {...notification};
                }
            );
            const updatedRecurrenceAlertIntervals = getClearedRecurrentIntervals([...state.recurrentAlertIntervals], uuid);
            const unreadCount = getUnreadNotificationCount(updatedNotifications);
            return {
                ...state,
                alerts: [...updatedAlerts],
                notifications: [...updatedNotifications],
                recurrentAlertIntervals: [...updatedRecurrenceAlertIntervals],
                count: unreadCount,
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.RESET: {
            // clear all recurrence intervals
            [...state.recurrentAlertIntervals].forEach((interval) => {clearInterval(interval.intervalId);});
            
            // clear all snooze timeouts
            [...state.snoozedAlertIntervals].forEach((timer) => {clearTimeout(timer.timeoutId);});
            return {
                ...state,
                count: 0,
                notifications: [],
                alerts: [],
                recurrentAlertIntervals: [],
                snoozedAlertIntervals: [],
                showNotificationCenter: false,
                showPushNotifications: true,
            };
        }
        case NOTIFICATION_REDUCER_CONSTANTS.MARK_ALL_AS_READ: {
            let existingNotifications = [...state.notifications];
            const readNotifications = existingNotifications.map((notification) => {
                return {
                    ...notification,
                    isRead: true,
                };
            });
            // clear all recurrence intervals
            [...state.recurrentAlertIntervals].forEach((interval) => {clearInterval(interval.intervalId);});
            return {
                ...state,
                notifications: [...readNotifications],
                alerts: [],
                recurrentAlertIntervals: [],
                count: 0,
            }
        }
        case NOTIFICATION_REDUCER_CONSTANTS.TOGGLE_NOTIFICATION_CENTER: {
            const { isVisible } = payload;
            return {
                ...state,
                showNotificationCenter: isVisible
            };
        }
        case NOTIFICATION_REDUCER_CONSTANTS.TOGGLE_PUSH_NOTIFICATIONS: {
            const { isVisible } = payload;
            return {
                ...state,
                showPushNotifications: isVisible
            };
        }
        default:
            return state;
    }
};