import { AppBar, Toolbar, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/system";

export const AppBarStyle = styled(AppBar)(({ theme }) => ({
  background: theme.customColors.black,
  flexGrow: 1,
  // Ensures app bar/header in default route overlays the left side menu bar
  zIndex: theme.zIndex.drawer + 1,
}));

export const ToolbarStyle = styled(Toolbar)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: "2em",
  fontSize: "1.5em",
  fontWeight: 700,
}));

export const TypographyTitleCenter = styled(Typography)(({ theme }) => ({
  fontSize: "1.5em",
  fontWeight: 700,
}));

export const TypographyUserText = styled(Typography)(({ theme }) => ({
  color: theme.customColors.black,
  textAlign: "center",
  letterSpacing: "unset",
  padding: "0.3em",
  textTransform: "uppercase",
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#181818',
    color: '#D0D0CE',
    display: 'flex',
    alignItems: 'center',
    width: '13.0625rem',
    height: '1.375rem',
    padding: '0.625rem 0.75rem 0.625rem 0.75rem',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    border: '0.0625rem solid #2D3338',
    boxShadow: '0.25rem 0.25rem 0.25rem  #00000080',
  },
}));