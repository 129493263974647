const MESSAGE_STRINGS = {
  // App Wrapper Labels
  "WRAPPER.header.editCompany.title": "Edit Company Details",
  "WRAPPER.header.editCompany.companyNameTitle": "Enter Company Name",
  "WRAPPER.header.editCompany.placeholder": "Enter Company Name",
  "WRAPPER.header.editCompany.cancel": "Cancel",
  "WRAPPER.header.editCompany.save": "Save",
  "WRAPPER.header.editCompany.updating": "Updating...",
  "WRAPPER.header.editCompany.companyNameEditSuccess":
    "Company Name updated successfully!",
  "WRAPPER.header.editCompany.error.emptyCompanyName":
    "Company Name cannot be empty.",
  "WRAPPER.header.editCompany.error.duplicateCompanyName":
    "Company Name already exists!",
  "WRAPPER.header.newui.navigation.text": "All Plants",

  "PLANT_LIST.row.explore": "Explore",
  "PLANT_LIST.row.completeConfiguration": "Complete Config",

  "BUSINESS_UNIT_LIST.row.explore": "Explore",
  "BUSINESS_UNIT_LIST.row.completeConfiguration": "Complete Config",

  "CONFIG_SIDE_BAR.heading.companyName": "Company Name",
  "CONFIG_SIDE_BAR.heading.businessUnit": "Business Unit",
  "CONFIG_SIDE_BAR.heading.plantName": "Plant Name",

  // WIP Targets
  "WIP_TARGETS.header.heading": "Targets",
  "WIP_TARGETS.captions.unsavedChangesPresent": "Unsaved Changes Present...",
  "WIP_TARGETS.captions.saveSuccessful": "Saved..",
  "WIP_TARGETS.captions.wipTargetsUpdating": "Updating Data..",
  "WIP_TARGETS.captions.errorFetchingTargetData":
    "There was error fetching targets data",
  "WIP_TARGETS.captions.saving": "Saving...",
  "WIP_TARGETS.captions.save": "Save",
  "WIP_TARGETS.captions.searchLinesPlaceholder": "Search Lines",
  "WIP_TARGETS.captions.searchCellsPlaceholder": "Search Cells",
  "WIP_TARGETS.captions.plants": "Plants",
  "WIP_TARGETS.captions.lines": "Lines",
  "WIP_TARGETS.captions.cells": "Cells",

  "THROUGHPUT_TARGETS.captions.searchLineNamePlaceholder": "Search Line names",

  // FACTORY HEALTH
  "FACTORY_HEALTH.type.wip": "WIP",
  "FACTORY_HEALTH.type.throughput": "Throughput",
  "FACTORY_HEALTH.status.notStarted": "Not Started",
  "FACTORY_HEALTH.status.inProgress": "In Progress",
  "FACTORY_HEALTH.status.completed": "Completed",
  "FACTORY_HEALTH.treeThing.header.WIP.header": "WIP",
  "FACTORY_HEALTH.treeThing.header.THROUGHPUT.header": "Value",
  "FACTORY_HEALTH.treeThing.header.WIP.tooltip":
    "WIP is a constant target value that you want to maintain throughout the production day, not a target value expected at the end of the production day",
  "FACTORY_HEALTH.treeThing.header.THROUGHPUT.tooltip":
    "Throughput is the expected value at the end of the production day.",
  "Factory_HEALTH.treeThing.loading.error":
    "There was trouble fetching hierarchy information",
  "Factory_HEALTH.treeThing.header.equipmentHierachy": "Equipment Hierachy",
  "Factory_HEALTH.treeThing.header.uom": "UoM",

  // ACTUALS
  "ACTUALS.accordionHeader": "Actuals",
  "FactoryHealth.actuals.headers.parameter": "Parameter",
  "FactoryHealth.actuals.headers.value": "Value",
  "FactoryHealth.actuals.UoM.dropdown.placeholder": "Select Value",
  "FactoryHealth.actuals.confirmModal.cancelText": "No",
  "FactoryHealth.actuals.confirmModal.saveText": "Yes",
  "FactoryHealth.actuals.confirmModal.title": "Confirm!",
  "FactoryHealth.actuals.confirmModal.caption.THROUGHPUT":
    "Changing UoM will delete the filled out values under Maximum Capacity and Target Throughputs per Production Day.",
  "FactoryHealth.actuals.confirmModal.caption.WIP":
    "Changing UoM will delete the filled out values under Targets.",
  "FactoryHealth.actuals.confirmModal.caption.confirmText":
    "Do you wish to change UoM?",
  "ACTUALS.failedToGetData": "Failed to get data",

  // MAXIMUM CAPACITY
  "MAXIMUM_CAPACITY.accordionHeader": "Maximum Capacity",
  "MAXIMUM_CAPACITY.accordion.tooltip.text":
    "You must select UoM in the Actuals section in order to enter values for Maximum Capacity.",
  "MAXIMUM_CAPACITY.plantName.text": "Plant:",
  "MAXIMUM_CAPACITY.caption.save": "Save",
  "MAXIMUM_CAPACITY.capation.successfulMessage":
    "Maximum Capacity successfully saved...",

  // TARGETS
  "TARGETS.wip.accordionHeader": "Targets",
  "TARGETS.throughput.accordionHeader": "Target Throughputs per Production Day",
  "TARGETS.uploadTitle": "Upload Targets CSV",
  "TARGETS.exportData": "Export Data",
  "TARGETS.uploadCsv": "Upload CSV",
  "TARGETS.fileUploadError": "Error occured while uploading file.",
  "TARGETS.accordion.tooltip.wip":
    "You must select UoM in the Actuals section in order to enter values for Targets.",
  "TARGETS.accordion.tooltip.throughput":
    "You must select UoM in the Actuals section in order to enter values for Target Throughputs per Production Day.",
  "TARGETS.toast.fileUploadedSuccessfully":
    "File has been successfully uploaded",
  "TARGETS.wip.aipUpdatedMessage":
    "There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Targets.",
  "TARGETS.throughput.aipUpdatedMessage":
    "There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Target Throughputs per Production Day.",

  // DEVIATION THRESHOLD
  "DEVIATION_THRESHOLD.accordionHeader": "Deviation Threshold",
  "DEVIATIONS.MESSAGES.ERROR.shouldNotBeGreaterThanUpper":
    "Upper bound should be greater than lower bound",
  "DEVIATIONS.MESSAGES.ERROR.shouldNotLowerThanLower":
    "Lower bound should be less than upper bound ",
  "DEVIATIONS.header.value": "Value",
  "DEVIATIONS.header.direction": "Direction",
  "DEVIATIONS.header.type": "Type",
  "DEVIATIONS.header.lowerBound": "Lower Bound",
  "DEVIATIONS.header.upperBound": "Upper Bound",
  "DEVIATIONS.label.tooltip.partA": "Actual throughput will be",
  "DEVIATIONS.label.tooltip.partB":
    "if its deviation from the throughput target is within the following range",

  // UnsavedErroMessage
  "UnsavedErrorMessage.message.errorMessage":
    "Note : You have some unsaved changes. Please save to continue",

  // Error messages
  "ErrorMessages.notWholeNumber.message": "Please Enter a valid whole number",
  "ErrorMessages.invalidNumber.message": "Please Enter a valid number",
  "ErrorMessages.betweenZeroHundred.message":
    "Value should be between 0 and 100",
  "ErrorMessages.nonUniqueAssignment.message":
    "Please assign different crew for shift",
  "ErrorMessages.errorSavingData.message": "Error saving data",

  "Toast.message.SUCCESS": "Your changes were saved Successfully!",
  "Toast.message.ERROR": "Oops, something went wrong. Please try again later!",
  "Toast.message.WARNING": "Oops, something went wrong!",
  "Toast.message.INFO": "Please wait",

  // General
  "Placeholders.enterHere": "Enter Here",
  "Symbol.global.percentageSymbol": "%",
  "Targets.heading.header": "Targets",
  "Targets.value.OEE": "OEE Value",
  "Targets.value.Availability": "Availability Value",
  "Targets.value.Performance": "Performance Value",
  "Targets.value.Quality": "Quality Value",
  "TOOL_TIP.timeRange":
    "Time Range defines the timeline and/or start and end time for capturing throughput data. For e.g. Shifts, Weeks, etc.",
  "TOOL_TIP.refreshRate":
    "Refresh rate is the number of times a display's image is repainted or refreshed",

  "ERROR_MESSAGES.secondsValidation": "Refresh Rate cannot be less than 15 sec",
  "ERROR_MESSAGES.zeroValidation": "Values should be greater than zero",
  "ERROR_MESSAGES.validWholeNumber": "Enter a valid whole number",
  "ERROR_MESSAGES.emptyField": "Field cannot be blank",
  "ERROR_MESSAGES.percentValidation": "Enter whole number between 0 and 100",
  "ERROR_MESSAGES.negativeValidation":
    "Values should be greater than or equal to zero",
  "ERROR_MESSAGES.noMatchingRows": "No matching rows!",
  "UNSAVED_MESSAGE.msg":
    "You have unsaved changes on this page. Please save to continue.",
  "NoOptionsMessage.Plants": "No Plants.",
  "NoOptionsMessage.Options": "No Options",

  // Rotations
  "ROTATIONS.WeekDays.header.Monday": "Mon",
  "ROTATIONS.WeekDays.header.Tuesday": "Tue",
  "ROTATIONS.WeekDays.header.Wednesday": "Wed",
  "ROTATIONS.WeekDays.header.Thursday": "Thu",
  "ROTATIONS.WeekDays.header.Friday": "Fri",
  "ROTATIONS.WeekDays.header.Saturday": "Sat",
  "ROTATIONS.WeekDays.header.Sunday": "Sun",
  "ROTATIONS.ProductionDay.caption.startTime": "Production Day Start Time",
  "ROTATIONS.ProductionDay.caption.endTime": "Production Day End Time",
  "ROTATION.productionDay.caption.sameDay": "Same Calendar Day",
  "ROTATION.productionDay.caption.nextDay": "Next Calendar Day",
  "ROTATIONS.ProductionDays.header.productionDay1": "Production Day 1",
  "ROTATIONS.ProductionDays.header.productionDay2": "Production Day 2",
  "ROTATIONS.ProductionDays.header.productionDay3": "Production Day 3",
  "ROTATIONS.ProductionDays.header.productionDay4": "Production Day 4",
  "ROTATIONS.ProductionDays.header.productionDay5": "Production Day 5",
  "ROTATIONS.ProductionDays.header.productionDay6": "Production Day 6",
  "ROTATIONS.ProductionDays.header.productionDay7": "Production Day 7",
  "ROTATIONS.noRotations.message": "No Rotations Found",
  "ROTATIONS.rotationAction.addRotation.inProgress": "adding...",
  "GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.customTitle":
    "Delete Rotation?",
  "GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.customTitle":
    "Add Rotation?",
  "GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.noteText":
    "Adding Rotation will affect the rest of the steps.",
  "GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.noteText":
    "Deleting Rotation will affect the rest of the steps.",
  "GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.customText.inProgress":
    "Rotation is being deleted...",
  "GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.customText.inProgress":
    "Rotation is being added...",
  "GENERAL_CONFIGURATIONS.Rotation.NOTIFICATION.customText":
    "Do you wish to delete this Rotation?",
  "GENERAL_CONFIGURATIONS.Rotation.ADD_NOTIFICATION.customText":
    "Do you wish to create a new Rotation?",
  "ROTATIONS.deleteRotation.success": "Rotation was deleted successfully!",
  "ROTATIONS.deleteRotation.error":
    "Oops there was problem deleting this rotation!",
  "ROTATIONS.addRotation.success": "New Rotation Created Successfully",
  "ROTATIONS.addRotation.error": "There was an error creating new rotations",
  "ROTATIONS.updateRotation.fieldErrors": "Please fix errors and submit again",
  "ROTATIONS.updateRotation.unassignedCrews":
    "Crews need to be assigned to proceed to next step",

  // General Configuration - Schedule
  "SCHEDULE.screenHeading": "Schedule",
  "SCHEDULE.heading.shiftName": "Shift Name",
  "SCHEDULE.heading.startTime": "Start Time",
  "SCHEDULE.heading.endTime": "End Time",
  "SCHEDULE.heading.endsOn": "Ends On",
  "SCHEDULE.heading.timezone": "Timezone",
  "SCHEDULE.heading.productionDay": "Production Day",
  "SCHEDULE.heading.shifts": "Shifts",
  "SCHEDULE.endsOn.sameDay": "Same Day",
  "SCHEDULE.endsOn.nextDay": "Next Day",
  "SCHEDULE.calendar.heading.shiftName": "Shift Name",
  "SCHEDULE.calendar.heading.startTime": "Start Time",
  "SCHEDULE.calendar.heading.endTime": "End Time",
  "SCHEDULE.calendar.heading.action": "Action",
  "SCHEDULE.calendar.shiftName.placeholder": "Name",
  "SCHEDULE.calendar.productionDayStartTime": "Production Day Start",
  "SCHEDULE.calendar.productionDayEndTime": "Production Day End",
  "SCHEDULE.calendar.calendarChangeTime": "Calendar Day Change",

  "SCHEDULE.productionDay.lessThanProductionDayStartTime":
    "End Time should be between 00:00 and the Production Day's Start Time",
  "SCHEDULE.ShiftNameError.duplicate": "Shift names cannot be duplicate",
  "SCHEDULE.ShiftNameError.empty": "Shift name cannot be empty",
  "SCHEDULE.ShiftTimeError.boundError":
    "Timings should be between production day timings",
  "SCHEDULE.ShiftTimeError.startTimeError":
    "End Time should be later than the Start Time",
  "SCHEDULE.ShiftTimeError.sameShiftTime":
    "Shifts cannot have same timings as another",
  "SCHEDULE.ShiftTimeError.invalidTime": "Invalid Time",
  "SCHEDULE.fieldCannotBeBlank": "Field cannot be blank",
  "SCHEDULE.NOTIFICATION.customTitle": "Delete Shift?",
  "SCHEDULE.NOTIFICATION.customText": "Do you wish to delete this Shift?",
  "SCHEDULE.NOTIFICATION.deleteText": "Do you wish to delete ",
  "SCHEDULE.NOTIFICATION.noteText":
    "Note: Deleting a shift will affect the rotation screen and will also delete all assigned crews.",
  "SCHEDULE.NOTIFICATION.customText.inProgress": "Shift is being deleted...",
  "SCHEDULE.MESSAGES.deleteShiftSuccess": "Deleted Shift Successfully",
  "SCHEDULE.ERROR_MESSAGES.deleteShiftFailed":
    "There was a problem deleting shift",
  "SCHEDULE.ERROR_MESSAGES.updateShiftsFailed":
    "There was a problem updating shifts",
  "SCHEDULE.ERROR_MESSAGES.createShiftsFailed":
    "There was a problem creating shifts",
  "SCHEDULE.ERROR_MESSAGES.resolveError": " Please resolve errors to continue",
  "SCHEDULE.NOTIFICATION.createShiftTitle": "Create Shift?",
  "SCHEDULE.NOTIFICATION.createShiftText": "Do you wish to create a new Shift?",
  "SCHEDULE.NOTIFICATION.updateShiftTitle": "Schedule",
  "SCHEDULE.NOTIFICATION.updateShiftText": "Do you wish to update schedule?",
  "SCHEDULE.NOTIFICATION.createShiftNoteText":
    "Note: Creating a shift will affect rotation screen.",
  "SCHEDULE.NOTIFICATION.updateShiftNoteText":
    "Note: The changes made to the production schedule will reflect across the system starting the next calendar day",

  // General Configurations
  "GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText": "No",
  "GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText": "Yes",
  "GENERAL_CONFIGURATIONS.Updating.message": "Updating...",
  "GENERAL_CONFIGURATIONS.header.heading": "General Configuration",

  // Crews Configuration
  "GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customTitle": "Delete Crew ?",
  "GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText":
    "Do you wish to delete this Crew?",
  "GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText.inProgress":
    "Crew is being deleted...",
  "GENERAL_CONFIGURATIONS.CREWS.unsavedChangesMessage":
    "Unsaved changes present",
  "GENERAL_CONFIGURATIONS.CREWS.issueFetchingCrews":
    "Issue Fetching Crews...Please refresh...",
  "GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameShouldUnique":
    "Crew Names Should be Unique",
  "GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameLength":
    "Should be atleast 1 characters long",
  "GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.failedUniqueNameGeneration":
    "Please re-assign a unique name",
  "GENERAL_CONFIGURATIONS.CREWS.MESSAGES.deleteCrewSuccess":
    "Deleted Crew Member Successfully",
  "GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.deleteCrewFailed":
    "There was a problem deleting crew member",
  "GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.updateCrewFailed":
    "There was a problem updating crew members",
  "GENERAL_CONFIGURATIONS.CREWS.tableHeader.action": "Action",
  "GENERAL_CONFIGURATIONS.CREWS.tableHeader.crewName": "Crew Name",
  "GENERAL_CONFIGURATIONS.CREWS.ADD.errorsPresent":
    "Remove errors before adding new crew members",
  "GENERAL_CONFIGURATIONS.CREWS.NEXT.errorsPresent":
    "Remove errors before next action",
  "GENERAL_CONFIGURATIONS.actions.previous": "Previous",
  "GENERAL_CONFIGURATIONS.actions.submit": "Submit",
  "CREWS_DROPDOWN.SelectCrew.default": "Select Crew",

  // Weekly and Daily Rotations
  "WeeklyAndDailyRotations.RotationsCalendar.uploadCsv.info":
    "If you have already uploaded a CSV file, clicking this icon again will allow you to upload a new CSV that will replace the previous CSV file. All data from the first CSV file will be removed.",

  // OEE
  "OEE.ActualsSection.Accordion.heading": "Actuals",
  "OEE.DeviationsSection.Accordion.heading": "Deviations",
  "GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.noteText":
    "Note: Deleting crew will affect rotation screen and will also delete all assigned crews.",
  "OEE.captions.plants": "Plants",
  "OEE.captions.lines": "Lines",
  "OEE.captions.cells": "Cells",
  "OEE.captions.assets": "Assets",
  "OEE.captions.searchLinesPlaceholder": "Search Lines",
  "OEE.captions.searchCellsPlaceholder": "Search Cells",
  "OEE.captions.searchAssetsPlaceholder": "Search Assets",

  // OEE ACTUALS
  "OEE_ACTUALS.HEADERS.parameter": "Parameter",
  "OEE_ACTUALS.HEADERS.value": "Value",
  "OEE_ACTUALS.HEADERS.uom": "UoM",
  // Actuals
  "ACTUALS.ToolTip.Time Range":
    "Time Range defines the timeline and/or start and end time for capturing throughput data. For e.g. Shifts, Weeks, etc.",
  "ACTUALS.ToolTip.Refresh Rate":
    "Refresh rate is the number of times a display's image is repainted or refreshed",

  "CONSTANT.FETCH.ERROR": "There was error fetching constants data",

  // Query LifeCycles
  "Query.message.isSaving": "Saving....",

  "GENERAL_CONFIGURATIONS.WEEKY_DAILY.fetchError":
    "Issue Fetching Data...Please refresh...",

  // Plant Financial Calculations (GPFC)
  "GPFC.pageHeading.heading": "Plant Financial Calculations",
  "GPFC.errors.validMoneyAmount": "Enter a valid number, eg: 250.10, 10.25",
  "GPFC.errors.should2decimal": "Should contain 2 decimal places",
  "GPFC.errors.submit.errorsPresent": "Please fix the errors and resubmit",
  "GPFC.headers.label": "",
  "GPFC.headers.value": "Value",
  "GPFC.headers.uom": "Per UoM of Production",
  "GPFC.heading.isPlantSoldout": "Is The Plant Sold Out? ",
  "GPFC.heading.contributionMargin": "Contribution Margin",
  "GPFC.heading.variableCost": "Variable Cost",
  "GPFC.plantSoldout.options.true": "Yes",
  "GPFC.plantSoldout.options.false": "No",
  "GPFC.tooltip.isPlantSoldout":
    "Plant Sold Out: The plant can sell every unit of additional production when sold out",
  "GPFC.tooltip.row.5":
    "Variable cost is the sum of labour, material, utility and miscellaneous variable costs",

  // Asset Intelligence
  "ASSET_INTELLIGENCE.title": "Asset Intelligence Parameters",
  "ASSET_INTELLIGENCE.uploadTitle":
    "UPLOAD ASSET INTELLIGENCE PARAMETERS FILE HERE",
  "ASSET_INTELLIGENCE.fileUploadError": "Error occured while uploading file",
  "ASSET_INTELLIGENCE.fileSuccessfullyUploaded": "File successfully uploaded!",
  "ASSET_INTELLIGENCE.popoverContent.heading":
    "Please review the definitions for the permissible values for the ‘Type of Parameter Tag’ field in the template",
  "ASSET_INTELLIGENCE.popoverContent.pointA": `a.	Recipe monitored setpoint value: The value entered on an asset by the line operator, often through an
	HMI screen`,
  "ASSET_INTELLIGENCE.popoverContent.pointB": `b. Recipe monitored present value:  The value of a parameter at the current point in time as measured by
	a sensor or controller`,
  "ASSET_INTELLIGENCE.popoverContent.pointC": `c.	Non-recipe present value: A attribute of an asset or the material flowing through said asset at the current
	point in time, not related to the recipe, such as the total count of goods passing through an asset`,
  "ASSET_INTELLIGENCE.errorMessage": "Error occured while processing CSV",
  "ASSET_INTELLIGENCE.captions.loading":
    "This process may take a few minutes, please wait",

  // Config Complete Page
  "configComplete.completedSteps.message":
    "is Completed For Factory ‘Smart Factory @  ",
  "configComplete.factoryHealth.link.message":
    "Start Configuration for Factory health",
  "configComplete.button.edit.label": "Edit",

  "GENERAL_CONFIGURATIONS.WEEKY_DAILY.onNextMessage":
    "Please upload Weekly and Daily Rotations CSV file before clicking on Next.",
  "GENERAL_CONFIGURATIONS.ASSET_INTELLIGENCE.onNextMessage":
    "Please upload Asset Intelligence CSV file to before clicking on Next.",
  "SCHEDULE.shiftOverlap.Errortext":
    "Shift times cannot overlap. Please enter mutually exclusive shift times.",
  "SCHEDULE.ShiftEmpty.ErrorText": "Please Enter Shift Timings",

  // Risk Category
  "CBM.riskCategory.headers.riskCategory": "Risk Category",
  "CBM.riskCategory.headers.lowerBound": "Lower Bound",
  "CBM.riskCategory.headers.upperBound": "Upper Bound",
  "CBM.riskCategory.validation.errors.shouldBeEmpty":
    "A Risk Category must have both UL and LL entered. Please enter both values for the risk category",
  "CBM.riskCategory.validation.errors.enterValidNumber":
    "Please enter whole numbers between 0 and 100",
  "CBM.riskCategory.validation.errors.mustBeGreaterThanEq0":
    "Must be greater than or equal to 0",
  "CBM.riskCategory.validation.errors.mustBeLessEq100":
    "Must be less than or equal to 100",
  "CBM.riskCategory.validation.errors.riskFullRangeError":
    "The Risk Categories must cover the range from 0% to 100% risk",
  "CBM.riskCategory.validation.errors.riskCategoryCantOverlap":
    "The range of one Risk Category can't overlap with the range of another Risk Category",
  "CBM.riskCategory.validation.errors.riskCategoryShouldNotOverlap":
    "The Risk Categories cannot overlap. Please enter mutually exclusive ranges for each category",
  "CBM.riskCategory.validation.errors.lowerBoundShouldBeLesser":
    "Lower Bound Should be less than Upper Bound",
  "CBM.riskCategory.validation.errors.upperBoundShouldBeGreater":
    "Upper Bound Should be greater than Lower bound",
  "CBM.riskCategory.validation.errors.lowerBoundLessThanPrev":
    "The Risk Categories cannot overlap. Please enter mutually exclusive ranges for each category",
  "CBM.riskCategory.validation.errors.upperBoundGreaterThanNext":
    "The Risk Categories cannot overlap. Please enter mutually exclusive ranges for each category",
  "CBM.riskCategory.validation.errors.riskCategoryBoth": `A Risk Category must have both Lower Bound and Upper Bound values entered.
	Please enter both values for the risk category`,
  "CBM.riskCategory.validation.errors.rangeBetween0To100":
    "Range should be between 0 to 100",

  // SOC View
  "SOCView.heading.mainHeader": "Standard Operating Conditions",
  "SOCView.heading.subHeading.addRecipe": "Add Recipe",
  "SOCView.AddRecipe.repeatedRecipeName":
    "Recipe Name already exists please enter a different name",
  "SOCView.AddRecipe.noRecipePresentAdd":
    "Enter Recipe Name and click enter to add row here",
  "SOCView.modal.heading.ADD_RECIPE": "Add Recipe",
  "SOCView.modal.rightButtonText.ADD_RECIPE": "Save",
  "SOCView.modal.heading.EDIT_RECIPE": "Edit Recipe",
  "SOCView.modal.rightButtonText.EDIT_RECIPE": "Save",
  "SOCView.modal.heading.DOWNLOAD_CSV": "Download CSV",
  "SOCView.modal.rightButtonText.DOWNLOAD_CSV": "Download",
  "SOCView.modal.delete.warning.heading": "Warning",
  "SOCView.modal.delete.warning.confirmQuestion": "Do you wish to continue?",
  "SOCView.modal.delete.warning.mainText":
    "Deleting a recipe will also delete all the standard operating conditions associated with it.",
  "SOCView.modal.subheading.downloadCsv": "Select Recipe",
  "SOCView.modal.subheading.enterRecipeName": "Enter Recipe Name",
  "SOCView.addRecipe.validationError.onlyCharacters":
    "Invalid recipe name format, valid format eg: Recipe 50",
  "SOCView.modal.downloadCsv.message.selectionLimitReached":
    "Row Limit Reached! You have selected the maximum number of Recipes allowed to be downloaded in one template",
  "SOCView.heading.uploadTitle": "Upload Standard Operating Conditions CSV",
  "SOCView.captions.exportData": "Export Data",
  "SOCView.heading.table.recipeName": "Recipe Name",
  "SOCView.error.rowLimitReached":
    "Row Limit Reached! You have selected the maximum number of Recipes allowed to be downloaded in one template ",
  "SOCView.error.fileUploadFailed": "Error in file upload",
  "SOCView.captions.uploadInfo.heading":
    "Please review the definitions for the permissible values for the ‘Type of Parameter Tag’ field in the template",
  "SOCView.captions.uploadInfo.pointA": `a. Recipe monitored setpoint value: The value entered on an asset by the line operator, often through an
	HMI screen`,
  "SOCView.captions.uploadInfo.pointB": `b. Recipe monitored present value: The value of a parameter at the current point in time as measured by
	a sensor or controller`,
  "SOCView.captions.uploadInfo.pointC": `c. Non-recipe present value: A attribute of an asset or the material flowing through said asset at the current
	point in time, not related to the recipe, such as the total count of goods passing through an asset`,
  "SOCView.captions.noRecipe":
    "Click on add recipe button to add recipe here...",
  "SOCView.socket.errorMessage": "Error occured while processing CSV",
  "SOCView.addRecipe.recipeName.validations.cantBeZero":
    "Invalid Input: Recipe name cannot be zero. Please enter a valid recipe",
  "SOCView.addRecipe.recipeName.validations.cantBeNegative":
    "Invalid Input: Recipe name cannot be negative. Please enter a valid recipe",
  "SOCView.addRecipe.recipeName.validations.cantBeBlank":
    "Invalid Input: Recipe name cannot be blank. Please enter a valid recipe",
  "SOCView.addRecipe.recipeName.validations.stringStartWithLetter":
    "Invalid Input: Recipe Name should start with character or be purely a number",
  "SOCView.addRecipe.messages.addingInProgress": "Add Recipes in progress...",
  "SOCView.addRecipe.toast.success": "Recipes were added successfully",
  "SOCView.addRecipe.toast.failure": "There was some trouble adding recipes",
  "SOCView.editRecipe.messages.editInProgress": "Edit Recipes in progress...",
  "SOCView.editRecipe.toast.success": "Edits were successfull",
  "SOCView.editRecipe.toast.failure": "There was trouble editing these recipes",
  "SOCView.fetchRecipe.updating": "Updating...",
  "SOCView.deleteRecipe.toast.success": "Recipes Delete Successfully",
  "SOCView.deleteRecipe.toast.failure": "Recipes Deletion Failed",
  "SOCView.deleteRecipe.messages.deletionInProgress": "Deleting Recipes...",
  "SOCView.captions.recipeUploadLoading":
    "This process may take a few minutes, please wait",

  // Create Plant Modal
  "CreatePlant.text": "Create Plant",
  "CreatePlant.text.plantName": "Enter Plant Name",
  "CreatePlant.text.businessUnit": "Enter Business Unit",
  "CreatePlant.text.street": "Street",
  "CreatePlant.text.city": "City",
  "CreatePlant.text.state": "State",
  "CreatePlant.text.zipcode": "Zip Code",
  "CreatePlant.placholder.street": "Enter Street",
  "CreatePlant.placholder.city": "Enter City",
  "CreatePlant.placholder.state": "Select State",
  "CreatePlant.placholder.businessUnit": "Select Business Unit",
  "CreatePlant.placholder.zipcode": "Enter Zip Code",
  "CreatePlant.buttonText.create": "Create",
  "CreatePlant.buttonText.cancel": "Cancel",
  "CreatePlant.validationMessage.zipcode": "Please enter valid Zip Code",
  "CreatePlant.successMessage": "Plant Created successfully",
  "CreatePlant.plantName.notUnique": "Plant with this name already exists",
  "CreatePlant.uploadImage.title": "Upload Image",
  "CreatePlant.uploadImage.uploadButtonText": "Browse",

  "CreatePlant.addAnotherPlant.customTitle": "Success!",
  "CreatePlant.addAnotherPlant.addAnotherPlantText": "Add Another Plant",
  "CreatePlant.addAnotherPlant.okay": "Okay",
  "CreatePlant.addAnotherPlant.customText":
    "You have successfully created the plant ",

  "UploadImageComponent.recommendedRes": "Recommended Resolution : ",

  // Constraint Managment
  // Constraint Actuals
  "ConstraintManagement.Actuals.HEADERS.parameter": "Parameter",
  "ConstraintManagement.Actuals.HEADERS.value": "Value",
  "ConstraintManagement.Actuals.HEADERS.uom": "UoM",
  "ConstraintManagement.Actuals.MainHeading": "Actuals",
  "ConstraintManagement.Targets.MainHeading": "Targets",
  "ConstraintManagement.MiscSettings.MainHeading": "Miscellaneous Settings",
  // Constraint Targets
  "ConstraintManagement.Targets.HEADERS.metrics": "Metrics",
  "ConstraintManagement.Targets.HEADERS.target": "Target",
  "ConstraintManagement.Targets.HEADERS.materialConstraint":
    "Material Constraint",
  "ConstraintManagement.Targets.HEADERS.equipmentConstraint":
    "Equipment Constraint",
  "ConstraintManagement.Targets.HEADERS.all": "All",
  "ConstraintManagement.Targets.targetType.open": "Open Constraints",
  "ConstraintManagement.Targets.targetType.closed": "Closed Constraints",
  "ConstraintManagement.Targets.targetType.totalDurationOpen":
    "Total Duration of Open Constraints",
  "ConstraintManagement.Targets.targetType.averageConstraintResolution":
    "Average Constraint Resolution Time",
  "ConstraintManagement.Targets.saveTargets.toast.emptyValuesAlert":
    " Please enter values in all fields",
  "ConstraintManagement.Targets.changeTargetsValue.errors.wholeNumbersOnly":
    "Please enter whole numbers only. Negative values are not permitted",
  "ConstraintManagement.Targets.changeTargetsValue.errors.enterNumericOnly":
    "Please enter numeric values only. Text and special characters are not permitted.",
  // Constraint Misc Settings
  "ConstraintManagement.MiscSettings.HEADERS.parameter": "Parameters",
  "ConstraintManagement.MiscSettings.HEADERS.value": "Snooze Time Duration",
  "ConstraintManagement.FaultCodes.MainHeading": "Fault Codes",
  "ConstraintManagement.FaultCodes.UploadTitle": "Upload Fault Codes CSV",
  "ConstraintManagement.FaultCodes.captions.exportData": "Export Data",
  "ConstraintManagement.FaultCodes.success.fileUpload":
    "CSV has been successfully uploaded",
  "ConstraintManagement.FaultCodes.error.fileUploadFailed":
    "Error in file upload",
  "ConstraintManagement.FaultCodes.error.fetchError":
    "Issue Fetching Data...Please refresh...",
  "ConstraintManagement.FaultCodes.captions.uploadInfo.pointA":
    "Fault Status Tag: Current state of fault (Active/Cleared).",
  "ConstraintManagement.FaultCodes.captions.uploadInfo.pointB":
    "Fault Code Tag: Unique number generated by the automation layer indication an active fault by a specific asset, sub-asset, component, or sub-component.",
  "ConstraintManagement.FaultCodes.captions.uploadInfo.pointC":
    "Fault Code Number: Unique number generated by the automation layer indication an active fault by a specific asset, sub-asset, component, or sub-component.",
  "ConstraintManagement.FaultCodes.captions.uploadInfo.pointD":
    "Fault Code Description: A text based description of the underlying fault that generated a fault code number.",

  // Asset Criticality
  "ASSET_CRITICALITY.captions.updateOnAssetIntelligence":
    "There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Asset Criticality file.",
  "ASSET_CRITICALITY.heading.uploadAssetCriticality":
    "UPLOAD ASSET CRITICALITY CSV FILE HERE",
  "ASSET_CRITICALITY.button.downloadCsvTemplate": "Download CSV Template",
  "ASSET_CRITICALITY.caption.fileSuccessfullyUpload":
    "File successfully uploaded",
  "ASSET_CRITICALITY.caption.fileUploadedSuccessfully":
    "File uploaded successfully",
  "ASSET_CRITICALITY.header.infoIcon.message":
    "Entering Criticality Ratings at the Asset level is optional and will be enabled only after Criticality Ratings have been uploaded for all Cells.Additionally, if Asset Criticality Ratings are left blank, the Criticality Ratings of the parent Cell are used for all Risk Factor calculations of Assets",

  //
  "CellCriticality.buttonText.uploadAssetCriticality":
    "UPLOAD CELL CRTICALITY CSV FILE HERE",
  "CellCriticality.captions.updateOnAssetIntelligence":
    "There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Cell Criticality file.",

  // Factory Accordion
  "Factory_Accordion.accordionHeading.isLoading": "Loading....",
  "Factory_Accordion.tableRegion.tableLoadFailed": "Failed to load data",

  "UPLOAD_CSV.error.rejectedFileType":
    "Invalid File type provided. Use CSV file.",

  // Map Wrapper
  "MapWrapper.drawer.plants.addEditButton": "Add/Edit Plant",
  "MapWrapper.drawer.plants.subHeading": "Plants",
  "MapWrapper.drawer.bu.addEditButton": "Add/Edit Business Unit",
  "MapWrapper.drawer.bu.subHeading": "Business Units",
  // Initial Company Setup-Modal
  "InitialCompanySetup.company.text.companyName": "Create Company Name",
  "InitialCompanySetup.company.placeholder.companyName": "Enter Company name",
  "InitialCompanySetup.company.errorMessage.empty":
    "Company Name cannot be empty.",
  "InitialCompanySetup.company.errorMessage.duplicate":
    "Company Name already exist.",
  "InitialCompanySetup.businessUnit.placeholder.businessunit":
    "Enter Business Unit",
  "InitialCompanySetup.businessUnit.errorMessage.empty":
    "Business Unit cannot be empty.",
  "InitialCompanySetup.businessunit.errorMessage.duplicate":
    "Business Unit already exists.Please enter a unique value.",
  "InitialCompanySetup.plant.errorMessage.emptyField": "Field cannot be blank",
  "InitialCompanySetup.plant.errorMessage.businessUnit":
    "Select a Business Unit.",
  "InitialCompanySetup.plant.errorMessage.state": "Select a State.",
  "InitialCompanySetup.plant.errorMessage.plantName.duplicate":
    "Plant name already exists. Please enter a unique value.",
  "InitialCompanySetup.modal.save": "Save",
  "InitialCompanySetup.modal.close": "Close",

  // AddEditPlantModal
  "AddEditPlantModal.createPlantForm.formHeader.CREATE": "Create Plant",
  "AddEditPlantModal.createPlantForm.formHeader.EDIT": "Edit Plant",
  "AddEditPlantModal.createPlantForm.formHeader.CreateNewPlant":
    "Create New Plant",
  "AddEditPlantModal.autoComplete.placeholder": "Search Plants...",

  // AddEditBU
  "AddEditBU.createBUForm.formHeader.addEdit": "Add/Edit Business Unit",
  "AddEditBU.createBUForm.errors.errorInForm":
    "Please fix the errors present in form",

  // Common words
  "CommonWords.Actions.Cancel": "Cancel",
  "CommonWords.Actions.Save": "Save",
  "CommonWords.Actions.Saving": "Saving...",
  "CommonWords.Actions.Updating": "Updating...",
  "CommonWords.errors.troubleFetchingDetails":
    "There was trouble fetching details",

  "FactoryHealth.Throughput.Targets.error.wholeNumber":
    "Please enter whole numbers only",
  "FactoryHealth.Throughput.Targets.error.emptyString": "Field cannot be blank",
  "FactoryHealth.Throughput.Targets.error.demonstratedCapacity":
    "Max Demonstrated Capacity should be less than or equal to Max Theoretical Capacity",
  "FactoryHealth.Throughput.Targets.error.targetCapacity":
    "Target Throughput should be less than or equal to Max Demonstrated Capacity",
  // ConfigOrderManagement
  "ConfigOrderManagement.miscSettings.frozenOrder.label":
    "Frozen Order Status Time Duration",
  "ConfigOrderManagement.miscSettings.frozenOrder.timeLabel": "Time",
  "ConfigOrderManagement.miscSettings.refreshRate.label": "Refresh Rate",
  "ConfigOrderManagement.miscSettings.heading": "Time Windows",
  "ConfigOrderManagement.miscSettings.frozenOrder.tooltipText.info":
    "Select the amount of time prior to a scheduled start time in which the order cannot be changed.",
  "ConfigOrderManagement.miscSettings.frozenOrder.tooltipText.example":
    "For example, if you select 15 minutes, a scheduled production order cannot be changed 15 minutes prior to the originally scheduled start time.",
  "ConfigOrderManagement.miscSettings.editableTimeWindow.label":
    "Editable Time Window for Orders within SFF",
  "ConfigOrderManagement.miscSettings.editableTimeWindow.tooltip.info":
    "Enter the timeframe when scheduled orders can be edited within SFF. The Start Time is the time that your plant's Scheduling System publishes a new schedule to SFF. The Duration is how frequently SFF receives a new schedule. Users cannot edit orders in SFF that are scheduled outside of this timeframe. For example, if the order editable Start Time in SFF is configured as 20:00 and the duration is 1 day, then SFF will receive a new schedule at 20:00 every day. This means that users can edit production orders in SFF scheduled between 20:00 on Monday until 20:00 on Tuesday; but an order scheduled at 21:00 on Tuesday could not be edited as it outside of the Editable Time Window for Orders within SFF.",
  "ConfigOrderManagement.miscSettings.approvalTime.label":
    "Approval Time Duration",
  "ConfigOrderManagement.miscSettings.approvalTime.tooltip.info":
    "Enter the amount of time a user has to review any proposed changes to the Production Schedule. If the changes are not manually approved within this timeframe, the system will automatically accept all changes.",
  "ConfigOrderManagement.miscSettings.approvalTime.durationLabel": "Duration",
  "ConfigOrderManagement.miscSettings.editableTime.startTimeLabel":
    "Start Time",
  "ConfigOrderManagement.miscSettings.editableTime.startTime.error":
    "Invalid Time.",
  "ConfigOrderManagement.miscSettings.editableTime.durationLabel": "Duration",
  "ConfigOrderManagement.miscSettings.errorText.approvalTime":
    "Approval Time should be less than or equal to Frozen Order Status Time.",
  "ConfigOrderManagement.systemIntegrations.viewBy.label":
    "View Production Order Duration by",
  "ConfigOrderManagement.systemIntegrations.heading": "System Integrations",
  "ConfigOrderManagement.systemIntegrations.viewBy.defaultViewByLabel":
    "Select View",
  "ConfigOrderManagement.systemIntegrations.viewBy.tooltipInfo":
    "Selecting one of these options will change how production order durations are viewed on the chart representation of the Production Schedule. For example, if you select Scheduled Cell, you will see the duration of a production order by each cell. If there are 5 cells in a zone, you will see the start and end times for each of the 5 cells, so there will be 5 blocks to represent one production order on the same row. Or, if you select Scheduled Zone, you would see the production order duration as the start time at cell 1 and the end time of cell 5. Furthermore, if that order must be processed through 2 zones, and you select Scheduled Line, then you would see the production order start time of the first zone and the end time at the end of the second zone.",
  "ConfigOrderManagement.changeoverTime.heading": "Changeover Time",
  "ConfigOrderManagement.changeoverTime.tooltipInfo":
    "Changeover Time is the amount of time between production orders on a zone. If there should not be any time in between orders, set the changeover time to 0 minutes.",
  // OEE-IdealRunrate
  "OEE.IdealRunRate.Accordion.header": "Ideal Run Rate",
  "OEE.IdealRunRate.header.lineLabel": "Lines",
  "OEE.IdealRunRate.header.idealRunRate": "Ideal Run Rate",
  "OEE.IdealRunRate.header.UoM": "UoM",
  "OEE.IdealRunRate.header.placeholder": "Select UoM",

  // Notification Configuration
  "NotificationConfiguration.pageTitle": "Notifications",
  "NotificationConfiguration.subTitle.generalSettings": "General Settings",
  "NotificationConfiguration.detailsTitle.notificationTiming":
    "Notification Timing",
  "NotificationConfiguration.duration": "Duration",
  "NotificationConfiguration.durationForNotificationCenterOnly":
    "Duration for Banner Notifications to Persist on the screen",
  "NotificationConfiguration.snoozeTimeForAlert":
    "Snooze Duration for Alert Notifications",
  "NotificationConfiguration.persistTimeForNotificationCenter":
    "Duration for all notifications to Persist in the Notification Center",
  "NotificationConfiguration.RiskSelection.notificationTriggerNote.part1":
    "Notifications will be triggered for all assets with ",

  "NotificationConfiguration.AddRemove.heading": "Add / Remove Notification",
  "NotificationConfiguration.AddRemove.table.heading1": "Functional Area",
  "NotificationConfiguration.AddRemove.table.heading2": "Notification Trigger",
  "NotificationConfiguration.AddRemove.table.heading3": "Added / Removed",
  "NotificationConfiguration.AddRemove.table.button.save": "Save",
  "NotificationConfiguration.AddRemove.table.button.close": "Close",
  "NotificationConfiguration.AddRemove.caption.saveMessage":
    "Your changes have been successfully saved!",
  "NotificationConfiguration.AddRemove.caption.noData":
    "No notfications data exists.",

  "NotificationConfiguration.emptyActiveNotifications":
    "Activated Notifications will display here.",
  "NotificationConfiguration.button.addNotifications": "Add Notifications",
  "NotificationConfiguration.detailsTitle.notificationTriggerSettings":
    "Notification Trigger Settings",
  "NotificationConfiguration.operationalArea": "Operational Area",
  "NotificationConfiguration.tableHeader.functionalArea": "Functional Area",
  "NotificationConfiguration.tableHeader.notificationTrigger":
    "Notification Trigger",
  "NotificationConfiguration.tableHeader.rolesAssigned": "Roles Assigned",
  "NotificationConfiguration.tableHeader.notificationType": "Notification Type",
  "NotificationConfiguration.tableHeader.recurrence": "Recurrence",
  "NotificationConfiguration.tableHeader.additionalSettings":
    "Addit'l Settings",
  "NotificationConfiguration.tableHeader.mute": "Mute",
  "NotificationConfiguration.button.resetFilters": "Reset Filters",
  "NotificationConfiguration.button.addOrRemove": "Add/Remove",
  "NotificationConfiguration.button.edit": "Edit",
  "NotificationConfiguration.button.save": "Save",
  "NotificationConfiguration.requiredFields": "Required Fields",
  "NotificationConfiguration.asterisk": "*",
  "NotificationConfiguration.error.completeRequiredFields":
    "Please complete the required fields.",

  "NotificationConfiguration.saveConfirmationModal.yes": "Yes",
  "NotificationConfiguration.saveConfirmationModal.no": "No",
  "NotificationConfiguration.saveConfirmationModal.title": "Save Changes",
  "NotificationConfiguration.saveConfirmationModal.saveChanges":
    "Do you wish to save changes?",

  "NotificationConfiguration.error.displayTime":
    "Please enter a value between 5 seconds and 60 seconds",
  "NotificationConfiguration.error.snoozeTimeMin":
    "The minimum duration for snooze time is 5 mins",
  "NotificationConfiguration.error.snoozeTimeMax":
    "The duration for snooze time cannot be greater than duration of production day",
  "NotificationConfiguration.error.persistTime":
    "Please enter a value between 4 hours and 48 hours",

  // Notification Configuration Additional Settings
  "NotificationConfiguration.AdditionalSettings.header.heading":
    "Additional Notification Settings",
  "NotificationConfiguration.AdditionalSettings.type.conditionBasedMonitoring":
    "Condition Based Monitoring",
  "NotificationConfiguration.AdditionalSettings.Modal.component.ASSET_CRITICALITY_THRESHOLD.heading":
    "Asset Criticality Treshold",
  "NotificationConfiguration.AdditionalSettings.Modal.component.ASSET_CRITICALITY_THRESHOLD.instruction":
    "Set criticality level that will trigger notifications for all assets with the selected criticality and higher.",
  "NotificationConfiguration.AdditionalSettings.Modal.component.PARAMETER_RISK_FACTOR_THRESHOLD.heading":
    "Parameter Risk Factor Threshold",
  "NotificationConfiguration.AdditionalSettings.Modal.component.PARAMETER_RISK_FACTOR_THRESHOLD.instruction":
    "Set risk factor level that will trigger notifications for all parameters with the selected risk factor and higher.",
  "NotificationConfiguration.Table.RolesAssigned.dialogTitle": "Select Roles",
  "NotificationConfiguration.Table.RolesAssigned.checkboxGroupTitle": "Roles",
  "NotificationConfiguration.Table.RolesAssigned.multipleRoles":
    "Multiple Roles",
  "NotificationConfiguration.Table.RolesAssigned.allRoles": "All Roles",
  "NotificationConfiguration.notificationType.defaultValue": "Select Type",

  "GeneralTable.Pagination.caption.label1": "Displaying",
  "GeneralTable.Pagination.caption.label2": "of",
  "GeneralTable.Pagination.caption.label3": "results",
  "GeneralTable.Pagination.caption.rowsLabel": "Rows per page",
  "TimeUom.days": "Days",
  "TimeUom.hours": "Hours",
  "TimeUom.minutes": "Minutes",
  "TimeUom.seconds": "Seconds",
  "NotificationConfiguration.AdditionalSettings.caption.noSettingFound":
    "No settings found",

  // Roles Additional settings
  "NotificationConfiguration.AdditionalSettings.RoleBased.errorMessage":
    "Days should be between 1 and 90.",
  "NotificationConfiguration.AdditionalSettings.OEE.errorMessage":
    "Your entry exceeds the maximum time range of 3 hours. Please enter a lower time range",

  // CBM Additional Settings
  "NotificationConfiguration.AdditionalSettings.CBM.CELLCRITICALITYTHRESHOLD.triggerMessage":
    "Notifications will be triggered for all cells with criticality of",
  "NotificationConfiguration.AdditionalSettings.CBM.CELLRISKCATEGORYTHRESHOLD.triggerMessage":
    "Notifications will be triggered for all cells with a risk category of",
  "NotificationConfiguration.AdditionalSettings.CBM.ASSETCRITICALITYTHRESHOLD.triggerMessage":
    "Notifications will be triggered for all assets with criticality of",
  "NotificationConfiguration.AdditionalSettings.CBM.ASSETRISKCRITICALITYTHRESHOLD.triggerMessage":
    "Notifications will be triggered for all assets with a risk category of",
  "NotificationConfiguration.AdditionalSettings.CBM.PARAMTERRISKCATEGORYTHRESHOLD.triggerMessage":
    "Notifications will be triggered for all parameters with a risk category of",

  // General Settings Footer
  "GeneralSettingsFooter.caption.save": "Save",
  "GeneralSettingsFooter.caption.cancel": "Cancel",

  // Factory Setting Sections,
  "FactorySettingSection.general_configuration": "General",
  "FactorySettingSection.factory_health": "Factory Health",
  "FactorySettingSection.SchedulePlanning": "Schedule Planning",
  "FactorySettingSection.oee_configuration": "OEE",
  "FactorySettingSection.condition_monitoring": "Manufacturing Equipment",
  "FactorySettingSection.manufacturing_equipment": "Manufacturing Equipment",
  "FactorySettingSection.constraint_management": "Constraint Management",
  "FactorySettingSection.order_management": "Dynamic Order Management",
  "FactorySettingSection.notifications": "Notification Configuration",

  //Notification Center Modal
  "NotificationCenter.Title": "Notifications",
  "NotificationCenter.Category.FunctionalArea": "Functional Area",
  "NotificationCenter.Category.Time": "Time",
  "NotificationCenter.Notification.ExpandAll": "Expand All",
  "NotificationCenter.Notification.CollapseAll": "Collapse All",
  "NotificationCenter.List.NotificationCenterOnly": "Notification Center Only",
  "NotificationCenter.List.FactoryHealth": "Factory Health",
  "NotificationCenter.List.Explore": "Explore",
  "NotificationCenter.EmptyNotification": "Notifications will appear here.",

  //Alert Notification
  "Alert.Explore": "Explore",
  "Alert.Snooze": "Snooze",
  "Alert.Snoozed": "Snoozed",

  // Demo Data Simulator
  "DDS.message.ERROR": "Oops, something went wrong. Please try again later!",
};

export const ROTATION_CALENDAR_MESSAGES = {
  TODAY: "Today",
  WEEK: "Week",
  SHIFT: "Shift",
  ROTATION: "Rotation",
  DAILY_ROTATION: "Daily Rotation",
  WEEKLY_ROTATION: "Weekly Rotation",
  WEEKLY: "Weekly",
  DAILY: "Daily",
  EXPORT_DATA: "Export Data",
  UPLOAD_CSV: "Upload CSV",
  TOOLTIP_HEADER: "Weekely and Daily Rotation",
  TOOLTIP_TEXT:
    "The dates and the days identified below correspond to production days and not calendar days. The specific dates and days are selected based on the Production Day Start Time and the logic for displaying shifts would correspond for the entire production day and not calendar day. For example, if the Production Day duration is from 19:00 to 07:00, since this duration crosses midnight, if the date January 1 is a Monday, then Production Day 1 would begin on Monday at 19:00 and end on Tuesday at 07:00. In the view below, all shifts for Production Day 1 will also be listed under Monday, January 1.",
};

export const MONTH_SHORT_FORMS = {
  JANUARY: "Jan",
  FEBRUARY: "Feb",
  MARCH: "Mar",
  APRIL: "Apr",
  MAY: "May",
  JUNE: "Jun",
  JULY: "Jul",
  AUGUST: "Aug",
  SEPTEMBER: "Sep",
  OCTOBER: "Oct",
  NOVEMBER: "Nov",
  DECEMBER: "Dec",
};

export const DAY_OF_MONTH_SUFFIXES = {
  TH: "th",
  ST: "st",
  ND: "nd",
  RD: "rd",
};

export const UPLOAD_COMPONENT_MESSAGES = {
  DOWNLOAD: "Download CSV Template",
  ROTATIONS_EMPTY: "Rotations can’t be empty in csv template. Retry Uploading.",
  REUPLOAD: "Re-Upload",
  KILO_BYTES: "kB",
  EXPORT_DATA: "Export Data",
  UPLOAD_CSV: "Upload CSV",
};

export const WEEKLY_AND_DAILY_ROTATION_MESSAGES = {
  TITLE: "Weekly & Daily Rotations",
  UPLOAD_TITLE: "UPLOAD WEEKLY ROTATION CSV FILE HERE",
  FILE_UPLOAD_ERROR: "Error occured while uploading file",
  NONE: "None",
};

export const CONFIG_SCREENS_MESSAGES = {
  SMART_FACTORY_AT: "Smart Factory @ ",
};

export const CBM_MESSAGES = {
  REFRESH_RATE: "Refresh Rate",
  CRITERIA_FOR_CRITICALITY_RATINGS: "Criteria For Criticality Ratings",
  ASSET_CRITICALITY: "Asset Criticality",
  CELL_CRITICALITY: "Cell Criticality",
  RISK_CATEGORY_THRESHOLDS: "Risk Category Thresholds",
  CSV_UPLOAD_ERROR:
    "Error occurred while uploading data. Please ensure that the column header text and the column order in the csv template remain unchanged",
  SAVE: "Save",

  LOADING_DATA: "Loading Data...",
  INITIAL_LOADING_MESSAGE: "Loading table data...",
  NO_DATA_AVAILABLE: "No Data Available",
  PLEASE_SELECT_A_FILTER: "Please select the filters",
  NO_DATA_AVAILABLE_FOR_SELECTED_FILTERS:
    "No data is available for the selected filter combination",
  NEW_EQUIPMENT_ADDED_WATCHLIST:
    "New equipment has been added to the AIP table. Please update and upload the Criticality Ratings for the new equipment through the Condition Based Monitoring Configuration screen",
  NEW_EQUIPMENT_ADDED_OPERATING_CONDITIONS:
    "New equipment has been added to the AIP table. Please update and upload the Standard Operating Conditions for the new equipment through the Condition Based Monitoring Configuration screen",
};

export const CRITICALITY_RATINGS_MESSAGES = {
  HIGHEST_RISK: "Highest Risk",
  LOWEST_RISK: "Lowest Risk",
  ENTER_TEXT_HERE: "Enter text here",
  SAME_VALUE_CANNOT_BE_ASSIGNED:
    "Same value cannot be assigned to multiple criticality ratings",
  ENSURE_ALL_FIELDS_ARE_FILLED: "Please enter criticality rating",
  NO_CHANGES_DETECTED: "No changes detected!",
  ASTERISK: "*",
};

export const USER_CONFIG_MESSAGES = {
  CREATE_USER_SUCCESS: "User has been successfully created!",
  CREATE_USER_ERROR: "Failed to create the user",
  CREATE_ROLE_SUCCESS: "Role has been successfully created!",
  CREATE_ROLE_ERROR: "Failed to create the role",

  UPDATE_USER_SUCCESS: "User has been successfully updated!",
  UPDATE_USER_ERROR: "Failed to update the user",
  UPDATE_ROLE_SUCCESS: "Role has been successfully updated!",
  UPDATE_ROLE_ERROR: "Failed to update the role",

  DELETE_USER_SUCCESS: "User has been successfully deleted!",
  DELETE_USER_ERROR: "Failed to delete the user",
  DELETE_ROLE_SUCCESS: "Role has been successfully deleted!",
  DELETE_ROLE_ERROR: "Failed to delete the role",

  DELETE_USER_DESCRIPTION_1:
    "	Deleting User will remove the permissions for associated user",
  DELETE_USER_DESCRIPTION_2: "Do you wish to delete user -",
  DELETE_ROLE_DESCRIPTION_1:
    "Deleting this Role will make the system inaccessible to any users with only this assigned role",
  DELETE_ROLE_DESCRIPTION_2: "Do you wish to delete role -",

  UPDATE_USER_DESCRIPTION_1: "Do you wish to update user?",
  UPDATE_ROLE_DESCRIPTION_1: "Do you wish to update role?",

  EMPTY_SEARCH: "Enter a valid string to search",
  SEARCH_SUCCESS: "Found users with matching names !",
  NO_USER_FOUND: "No user found !",
  TOOLTIP_TEXT: [
    "Please review the definitions for the permissions:",
    [
      "a. ",
      " View Only: ",
      "The user will be able to only view only day to day screen",
    ],
    [
      "b. ",
      " Edit Only: ",
      "The user will be able to edit data on the Day to day screen",
    ],
    [
      "c. ",
      " Edit and Configuration: ",
      " The user will be able to configure and edit data on the day to day screens",
    ],
    [
      "d. ",
      " System Admin: ",
      " The user will be able to perform all of the above permissions and will be able to access the user access configuration",
    ],
  ],
  INVALID_MESSAGE_CHECK: "Invalid end date !",
  ERROR_MESSAGE: [
    "Please select a valid option!",
    "Invalid Date, end date lesser than start date!",
    "Invalid Date! The Role Assignment Start Date cannot be lesser than the User Access Start Date!",
    "Invalid Date! The Role Assignment End Date cannot be greater than the User Access End Date!",
    "The entered date must be equal to or greater than today",
  ],
  SEARCH_ROLE: "Search Roles..",
  SEARCH_USER: "Search Users..",
};

export const UPLOAD_ERROR_MSG = {
  "file-invalid-type": "File type must be jpeg or png",
  "file-too-large": "File size should be less than 100 KB",
  "too-many-files": "Upload a single file",
};

export const RBAC_MESSAGES = {
  FETCHING_DETAILS: "Fetching your role information...",
  ROLE_DELETED_OR_EXPIRED:
    "The data access permissions configured with your account are no longer valid.",
  USER_ACCESS_ERROR_BEFORE_USER_START_DATE:
    "The access has not been initialized.",
  USER_ACCESS_ERROR_AFTER_USER_START_DATE_BEFORE_ROLE_START_DATE:
    "The data access permissions configured with your account have not been initialized.",
  ERROR_ASSISTANCE_DESCRIPTION:
    "Please reach out to the ITS team for assistance",
};

export const CONSTRAINT_MANAGEMENT_MESSAGES = {
  SNOOZE_ERROR: [
    "Note:",
    "Snooze time should be equal or less than",
    "The Snooze Time entered for the constraint is longer than the Maximum Snooze Time permitted for this constraint type. Please enter a time equal to or less than",
    "Select Snooze Time field can’t be empty",
  ],
  REOPEN: {
    DESCRIPTION: "You are about to re-open",
    QUESTION: "Do you wish to continue?",
    SUCCESS: "Constraint has been succesfully Re-opened",
    ERROR: "Failed to Re-open the constraint",
  },
  SNOOZE: {
    DESCRIPTION:
      'You are about to snooze this constraint, which will remove the constraint from the "Open Constraints" tab for the selected Snooze Time.',
    QUESTION: "Do you wish to snooze this constraint?",
    SUCCESS: "Constraint has been succesfully snoozed",
    ERROR: "Failed to snooze constraint",
  },
  CORRECTIVE_ACTIONS: {
    SUCCESS: "You have successfully saved notes",
    ERROR: "Failed to save notes",
  },
};

export const LOADER_MESSAGES = {
  FAILED_TO_LOAD_DATA: "Failed to load data",
};

export const DOM_MESSAGES = {
  EDIT_HOVER: {
    NO_WORKABLE: "There are no editable orders within the selected time range.",
    OUTSIDE_TIME_RANGE:
      "Edit mode is disabled for this selected time range because you cannot make any edits outside of the Editable Time Window.",
  },
};

export const CONFIGURATION_ALERT_MESSAGES = {
  FINISH_CONFIGURATION: "Please finish configuration to see Data",
  CONTACT_SYSTEM_ADMIN: "Please contact your system administrator",
};

export const CONFIRMER_MESSAGES = {
  DEMO_CLOSE: "You are about to end the demo",
  UNSAVED_CHANGES: "Navigating away will result in losing any unsaved changes.",
};

export default MESSAGE_STRINGS;
