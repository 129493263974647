import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { TransitionGroup } from 'react-transition-group';

const PushNotificationsContainer = styled(Box)(({ theme }) => ({
  zIndex: '10000',
  position: 'absolute',
  top: '4.5rem',
  right: '1.563rem',
}));

const StyledTransitionGroup = styled(TransitionGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export { PushNotificationsContainer, StyledTransitionGroup };
