import { globalstate$ } from "@smf/ui-util-app";
import { useEffect, useState } from "react";

export const useRxjsState = () => {
  const [rxjsState, setRxjsState] = useState({});

  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setRxjsState(globalstate);
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  return { rxjsState };
};
