import { globalstate$ } from "@smf/ui-util-app";

// export const updateCompanyData = (companyData) => {
//     const state = globalstate$.getValue();
//     globalstate$.next({ ...state, companyData });
// }

// export const updateUserInformation = (userInformation) => {
//     const state = globalstate$.getValue();
//     globalstate$.next({ ...state, userInformation });
// }

export const updateRxjsState = (data) => {
  const state = globalstate$.getValue();
  globalstate$.next({ ...state, ...data });
};

/**
 * Function to Update Global state when user tries to navigate away from FactoryGeneral Page
 * @param {Object} actionObj Object to update FactoryGeneralEditState
 */
export const updateFactoryGeneralEditState = (actionObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    factoryGeneralEditState: {
      ...state.factoryGeneralEditState,
      action: {
        ...state.factoryGeneralEditState.action,
        ...actionObj,
      },
    },
  });
};

export const updateDDSEventState = (actionObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    ddsNavigateAwayConfig: {
      ...state.ddsNavigateAwayConfig,
      action: {
        ...state.ddsNavigateAwayConfig.action,
        ...actionObj,
      },
    },
  });
};

export const updatePerformanceManagementSelectedTab = (tabValue) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    performaneManagementSelectedTab: tabValue,
  });
};
