import { useEffect, useState } from 'react';
import Alert from '../../components/Alert';
import { Collapse } from '@mui/material';
import { NOTIFICATION_TYPES, NOTIFICATION_DURATION_UOM } from '../../constants';
import { PushNotificationsContainer, StyledTransitionGroup } from './style';

const PushNotifications = ({
  alertsData,
  recurrenceIntervals,
  snoozeIntervals,
  dismissNotification,
  toggleSnooze,
  updateRecurrenceInterval,
  updateSnoozeTimer,
  appendExistingAlert,
}) => {
  const [alertsList, setAlertsList] = useState([]);

  const handleDismiss = (uuid, type) => {
    dismissNotification(uuid, type);
  };

  const handleSnooze = async (alert) => {
    // Snooze notification
    await toggleSnooze(alert.uuid, Date.now(), true, null);
    const snoozeConverter =
      alert.snoozeDurationUoM === NOTIFICATION_DURATION_UOM.HOURS
        ? 3600000
        : 60000;
    initializeSnoozeIntervals(alert, alert.snoozeDuration * snoozeConverter);
  };

  const getNewKeyToDisplayAlert = (alert) => {
    return `${Date.now()}_${alert.uuid}_${alert.triggerId}_${
      alert.notificationId
    }`;
  };

  const initializeSnoozeIntervals = (alert, snoozeTime) => {
    if (!snoozeIntervals.find((interval) => interval.uuid === alert.uuid)) {
      const timeoutId = setTimeout(async () => {
        console.info('snooze timeout called');
        const updatedAlert = {
          ...alert,
          isSnoozed: false,
          isRead: false,
          key: getNewKeyToDisplayAlert(alert),
        };
        // Unsnooze notification
        await toggleSnooze(
          alert.uuid,
          alert.snoozeStartTime,
          false,
          updatedAlert
        );
      }, snoozeTime);

      updateSnoozeTimer(alert.uuid, timeoutId);
    }
  };

  const initializeRecurrenceIntervals = (alert) => {
    if (!recurrenceIntervals.find((interval) => interval.uuid === alert.uuid)) {
      const intervalId = setInterval(() => {
        console.info('recurrence interval called');
        appendExistingAlert(
          { ...alert, key: getNewKeyToDisplayAlert(alert), isRead: false },
          false
        );
      }, alert.recurrenceValue * 60 * 1000);

      updateRecurrenceInterval(alert.uuid, intervalId);
    }
  };

  const getFilteredActiveAlerts = (alerts = []) => {
    const filteredAlerts = [];

    alerts.forEach((alert) => {
      if (alert.isSnoozed && alert.snoozeStartTime) {
        const snoozeDurationInMs =
          alert.snoozeDuration *
          (alert.snoozeDurationUoM === NOTIFICATION_DURATION_UOM.HOURS
            ? 3600000
            : 60000);
        const pendingTime =
          snoozeDurationInMs - (Date.now() - parseInt(alert.snoozeStartTime));
        initializeSnoozeIntervals(alert, pendingTime);
      } else if (alert.recurrence && alert.recurrenceValue) {
        initializeRecurrenceIntervals(alert);
        filteredAlerts.push({ ...alert });
      } else {
        filteredAlerts.push({ ...alert });
      }
    });
    return filteredAlerts;
  };

  useEffect(() => {
    if (alertsData) {
      const filteredAlerts = getFilteredActiveAlerts(alertsData);
      setAlertsList(
        filteredAlerts.length > 5
          ? filteredAlerts.slice(0, 5)
          : [...filteredAlerts]
      );
    }
  }, [alertsData]);

  return (
    <PushNotificationsContainer>
      <StyledTransitionGroup>
        {alertsList.length &&
          alertsList.map((alertData, idx) => (
            <Collapse
              key={alertData.key}
              timeout={300}
              data-testid="alert-collapse"
            >
              <Alert
                notificationData={alertData}
                dismissNotification={() => {
                  handleDismiss(alertData.uuid, NOTIFICATION_TYPES.ALERT);
                }}
                snoozeNotification={() => {
                  handleSnooze(alertData);
                }}
                notificationType={NOTIFICATION_TYPES.ALERT}
              />
            </Collapse>
          ))}
      </StyledTransitionGroup>
    </PushNotificationsContainer>
  );
};

export default PushNotifications;
