import { globalWebsocket, globalstate$,DDS_CONSTANTS } from "@smf/ui-util-app";
import { navigateToUrl } from "single-spa";

/**
 * Callback function Implementation for DDS-WebSocket connection OnSuccess Use Case
 *
 * @param websocket  websocket object
 * @param data  success response from websocket
 *
 * @author Aniket Soni
 */
export const onDemoReady = (data) => {
  const channel = DDS_CONSTANTS.WSS_CHANNEL;
  if (data?.status == DDS_CONSTANTS.STATE.READY) {
    globalstate$.next({
      ...globalstate$.value,
      ddsDemoStatus: {
        ...globalstate$.value?.ddsDemoStatus,
        [data?.demoName]: DDS_CONSTANTS.STATE.READY,
      },
    });
    navigateToUrl("/");
  } else {
    let demoStatus = { ...globalstate$.value?.ddsDemoStatus };
    delete demoStatus[data.demoName];
    globalstate$.next({
      ...globalstate$.value,
      ddsDemoStatus: { ...demoStatus },
      wss: {
        ...globalstate$.value.wss,
        [channel]: {
           ...globalstate$.value.wss[channel],
           errorStatus: true,
           errorMessage: `Something went wrong while starting demo`
        },
     },
    });
  }
  globalWebsocket.closeConnection(channel);
};

/**
 * Callback function Implementation for DDS-WebSocker connection OnError Use Case
 *
 * @param websocket  websocket object
 * @param data  error response from websocket
 *
 * @author Aniket Soni
 */
export const onDemoReadyFailed = (_e) => {
  const channel = DDS_CONSTANTS.WSS_CHANNEL;
  globalstate$.next({
    ...globalstate$.value,
    ddsDemoStatus: {},
    wss: {
      ...globalstate$.value.wss,
      [channel]: {
         ...globalstate$.value.wss[channel],
         errorStatus: true,
         errorMessage: `Something went wrong while starting demo`
      },
   },
  });
  globalWebsocket.closeConnection(channel);
};
