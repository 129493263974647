import { Box, Icon, Typography, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '../../assets/img/smallErrorIcon.svg';

const IconStyle = styled(Icon)(() => ({
	display: 'flex',
	alignItems: 'center',
}))

const TypographyMessageStyles = styled(Typography)(({theme}) => ({
	color: theme.customColors.white,
	fontSize: (props) => props.fontSize,
}))

function InlineErrorMessages({
	message = '',
	width = '20em',
	fontSize = '1rem',
	alignRight = false,
}) {
	return (
		<Box
			width={width}
			display='flex'
			paddingTop={1}
			component='span'
			alignItems='flex-start'
		>
			<IconStyle component='span'>
				<ErrorIcon />
			</IconStyle>
			<TypographyMessageStyles
				variant='subtitle1'
				component='span'
				align={alignRight ? 'right' : 'left'}
			>
				{message}
			</TypographyMessageStyles>
		</Box>
	);
}
InlineErrorMessages.propTypes = {
	width: PropTypes.string,
	message: PropTypes.string,
	fontSize: PropTypes.string,
	alignRight: PropTypes.bool,
};
export default InlineErrorMessages;
