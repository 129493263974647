import { Card, IconButton } from '@mui/material'
import { styled } from '@mui/system'

export const CardContent = styled(Card)(({ theme }) => ({
  height: '2.5rem',
  width: '14rem',
  backgroundColor: theme.customColors.blackGrey,
  zIndex: '1',
  display: 'flex',
  justifyContent: 'space-evenly',
  marginLeft: 'auto',
  alignItems: 'center',
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
}))

export const StyledImg = styled('img')(() => ({
  height: '2rem',
}))

export const IconButtonStyle = styled(IconButton)(() => ({
  '&:disabled': {
    opacity: 0.4,
  },
}))

export const StyledDiv = styled('div')(() => ({
  fontSize: '.6rem',
}))
