import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line no-unused-vars

const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function ReactQueryProvider({ children }) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
		</QueryClientProvider>
	);
}

export default ReactQueryProvider;
