import { useEffect } from 'react';
import { globalstate$, websocket as ws } from '@smf/ui-util-app';
import { useRxjsState } from './useRxjsState';
import { useToastContext } from '../../context/toastContext';
import { TOAST_REDUCER_CONSTANTS } from '../../constants';
import { updateRxjsState } from '../rxjsStateNext';

const simulateUrlClick = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('type', '.csv, text/csv');
  link.setAttribute('download', true);
  link.setAttribute('referrerpolicy', 'same-origin');
  link.click();
};

const useWebsocketListener = () => {
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  useEffect(() => {
    let websocket = rxjsState.websocket;

    if (websocket) {
      websocket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        let prevState = globalstate$.getValue();

        if (data.isError) {
          let showToast = true;
          ws.closeConnection();
          if (prevState.recipeUpload.status === 'inProgress') {
            updateRxjsState({
              recipeUpload: {
                ...prevState.recipeUpload,
                status: 'failed',
                errorMessage: data.errorMessage,
                uploadStatus:data.status,
                errors: data.errors,
              },
            });
            showToast = false;
          }
          if (showToast) {
            toastDispatch({
              type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
              payload: { message: data.errorMessage },
            });
          }
          return;
        }

        if (data.data) {
          simulateUrlClick(data.data);
          return;
        }

        updateRxjsState({
          recipeUpload: {
            ...prevState.recipeUpload,
            status: 'success',
            fileName: data.fileName,
            uploadStatus:data.status
          },
        });
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: { message: data.message },
        });
        return;
      };

      websocket.onclose = (e) => {
        updateRxjsState({
          websocket: null,
        });
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rxjsState.websocket]);

  useEffect(() => {
    if (rxjsState.websocketConnectionError) {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: { message: 'Error Connecting!, Please reload the app.' },
      });
      updateRxjsState({
        websocketConnectionError: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rxjsState.websocketConnectionError]);
};

export default useWebsocketListener;
