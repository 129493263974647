import { serializeError } from "serialize-error";
import { config } from "@smf/ui-util-app";
import { saveLogs } from "./utils/apiHelper";

// ERROR TRACE TRIM LENGTHS
const TOP_LEVEL_TRIM_LENGTH = 2000;
const INNER_LEVEL_TRIM_LENGTH = 1000;

/**
 * Overrides browser log function (log, warn, error, debug, trace, table)
 * Adds timestamp, user details to the log messages.
 * Disabled for localhost and .dev URLs.
 */
const initCustomLogger = () => {
  const appURL = config.COGNITO_REDIRECT_URL;
  // TODO: Change this condition once Centralized logging implemented.
  if (appURL.includes("localhost") || appURL.includes(".dev")) {
    return; // Disable log override in Local and Dev
  }

  const _log = window.console.log.bind(window.console);
  const _warn = window.console.warn.bind(window.console);
  const _error = window.console.error.bind(window.console);
  const _debug = window.console.debug.bind(window.console);
  const _trace = window.console.trace.bind(window.console);
  const _table = window.console.table.bind(window.console);

  // Fetch brwoser details.
  let userAgent = navigator.userAgent;
  if (navigator.userAgentData) {
    userAgent = navigator.userAgentData;
  }

  const isLogRestrictedToSave = (logHeader, errorTrace) => {
    if (typeof logHeader === "string") {
      const isAPIError = logHeader.includes("API | Error Code");
      const isAPIError404 = logHeader.includes("API | Error Code: 404");
      return (
        isAPIError &&
        (!isAPIError404 || errorTrace?.[1]?.config?.url?.includes("/saveLogs"))
      );
    }
    return true;
  };

  /**
   * Print logs to browser console.
   * @param {*} logObj
   * @param {*} level
   * @param {*} input
   */
  const applyLog = (logObj, level, input) => {
    logObj.apply(console, [`SMF-OLD | ${Date.now()} | ${level} |`, input]);
  };

  /**
   * Trim ErrorTrace's values to sprcified char limit
   * @param {*} customJSON
   * @param {*} trimSize
   */
  const trimErrorTrace = (errorTrace, trimSize) => {
    if (
      errorTrace &&
      typeof errorTrace === "object" &&
      !Array.isArray(errorTrace)
    ) {
      let newerrorTrace = { ...errorTrace };
      Object.keys(newerrorTrace).forEach(function (key) {
        if (typeof newerrorTrace[key] === "string") {
          newerrorTrace[key] = newerrorTrace[key].substring(0, trimSize);
        }
      });
      return newerrorTrace;
    }
    return errorTrace;
  };

  /**
   * Overrides console.log
   */
  window.console.log = (...input) => {
    applyLog(_log, "INFO", input);
  };

  /**
   * Overrides console.error
   */
  window.console.error = (...input) => {
    const errorTrace = serializeError({
      ...input,
    });
    const customJSON = {
      userAgent,
      errorTrace: trimErrorTrace(errorTrace, TOP_LEVEL_TRIM_LENGTH),
    };
    applyLog(_error, 'ERROR', input);
    if (!isLogRestrictedToSave(input[0] ?? '', errorTrace)) {
      saveLogs({
        logType: 'error',
        logs: customJSON,
      });
    }
  };

  window.onerror = function myErrorHandler(
    errorMessage,
    url,
    lineNumber,
    columnNumber,
    error
  ) {
    console.error("WINDOW ON_ERROR: ", {
      errorMessage,
      url,
      lineNumber,
      columnNumber,
      error: error?.stack ?? trimErrorTrace(error, INNER_LEVEL_TRIM_LENGTH),
    });
    return false;
  };

  /**
   * Overrides console.warn
   */
  window.console.warn = (...input) => {
    applyLog(_warn, "WARN", input);
  };

  /**
   * Overrides console.debug
   */
  window.console.debug = (...input) => {
    applyLog(_debug, "DEBUG", input);
  };

  /**
   * Overrides console.trace
   */
  window.console.trace = (...input) => {
    applyLog(_trace, "TRACE", input);
  };

  /**
   * Overrides console.table
   */
  window.console.table = (...input) => {
    applyLog(_table, "INFO", input);
  };
};

export default initCustomLogger;
